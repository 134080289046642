.main-text-area-comment {
  align-items: center;
  padding: 12px 16px;
}

.text-area-comment {
  height: 24px;
  resize: none;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
