
.rdl-filter, .rdl-control {
    @apply bg-white text-slate-800 border-slate-200 #{!important};
}

.react-dual-listbox * {
    @apply py-2;
}

.rdl-btn {
    @apply bg-white text-slate-800 border-slate-200 #{!important};
    
    &:hover:not(:disabled) {
        @apply border-slate-200 #{!important};
    }
}

:is(.twitter-picker, .sketch-picker, .chrome-picker, .compact-picker, .github-picker){
    @apply bg-white #{!important};
}