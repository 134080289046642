.videoPlayer {
  position: relative;
  width: 100%;
  background-color: black;
  aspect-ratio: 16/9;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
  backdrop-filter: blur(2px);
  padding: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.controls.visible {
  opacity: 1;
}

.controlsBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  gap: 8px;
  padding: 0 4px;
}

.leftControls,
.centerControls,
.rightControls {
  display: flex;
  align-items: center;
  gap: 4px;
}

@media (max-width: 480px) {
  .leftControls,
  .rightControls {
    flex: 0 0 auto;
  }
  
  .centerControls {
    flex: 1 1 auto;
    justify-content: center;
  }
}

.timelineContainer {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.timeLabels {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 12px;
}

.timeLabel {
  opacity: 0.9;
}

.timeline {
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  cursor: pointer;
  position: relative;
}

.timelineProgress {
  height: 100%;
  background-color: var(--primary);
  border-radius: 2px;
  position: relative;
}

.timelineHandle {
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background-color: var(--primary);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.timeline:hover .timelineHandle {
  opacity: 1;
}

.timeDisplay {
  color: #fff;
  font-size: 14px;
  min-width: 50px;
}

.volumeControl {
  display: flex;
  align-items: center;
  gap: 8px;
}

.volumeSlider {
  width: 48px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
}

.volumeProgress {
  height: 100%;
  background-color: var(--primary);
  border-radius: 2px;
}

@media (max-width: 480px) {
  .volumeSlider {
    width: 36px;
  }
}

.controlButton,
.playButton,
.muteButton {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: white;
  opacity: 0.9;
  transition: opacity 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controlButton:hover,
.playButton:hover,
.muteButton:hover {
  opacity: 1;
}

.controlButton.active {
  color: var(--primary);
}

.playButton {
  transform: scale(1.2);
}

.playbackControls,
.optionsControls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.miniplayer {
  width: 320px !important;
  height: 180px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  cursor: move;
}

.miniplayer .controls {
  transform: translateY(100%);
  transition: transform 0.2s ease;
}

.miniplayer:hover .controls {
  transform: translateY(0);
}

/* Mobile Controls Styles */
.mobileControls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.mobileControls.visible {
  opacity: 1;
  pointer-events: auto;
}

.mobileControlsOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  transition: backdrop-filter 0.3s ease, background-color 0.3s ease;
}

.mobileTimelineContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
}

.mobileTimelineContainer .timeline {
  height: 3px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0;
}

.mobileTimelineContainer .timelineProgress {
  background-color: var(--primary);
  height: 100%;
}

.mobileTimelineContainer .timelineHandle {
  display: none;
}

.mobileButtonsContainer {
  position: static;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileControlButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: white;
  opacity: 0.9;
  transition: opacity 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileControlButton:hover,
.mobileControlButton:focus {
  opacity: 1;
}

.mobilePlayButton {
  transform: scale(1.2);
}

.mobileFullscreenButton {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
