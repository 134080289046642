
.css-13cymwt-control {
    @apply border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 disabled:border-slate-300  disabled:text-slate-500  placeholder:text-slate-400 hover:border-slate-200  #{!important};
    
    .css-1u9des2-indicatorSeparator {
        @apply bg-slate-200 ;
    }
    
    .css-1p3m7a8-multiValue {
        @apply bg-custom-500 ;
        
        .css-wsp0cs-MultiValueGeneric {
            @apply text-white ;
        }
    }
}

.css-1nmdiq5-menu {
    @apply bg-white shadow-lg shadow-slate-200 #{!important};
    
    .css-d7l1ni-option {
        @apply bg-slate-100 ;
    }
}

[aria-disabled="true"].css-16xfy0z-control {
    @apply border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300  disabled:text-slate-500  placeholder:text-slate-400  #{!important};
}

.css-t3ipsp-control {
    @apply bg-white #{!important};
}

.css-qbdosj-Input, .css-166bipr-Input, .css-1fdsijx-ValueContainer {
    @apply text-slate-700 #{!important};
}