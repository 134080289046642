@import "./variable.scss";

:root {
  --primary: #{$primary};
  --darkblue: #{$darkblue};
  --secondary: #{$secondary};
  --blue: #{$blue};
  --black: #{$black};
  --lightbluebg: #{$lightbluebg};
  --white: #{$white};
  --bordercolor: #{$bordercolor};
  --gray500: #{$gray500};
  --darkblack: #{$darkblack};
  --red: #{$red};
  --lightgraybg: #{$lightgraybg};
  --lightgray400: #{$lightgray400};
  --green: #{$green};
  --lightcoffee: #{$lightcoffee};
  --black200: #{$black200};
  --lightgray300: #{$lightgray300};
  --lightgay700: #{$lightgay700};
  --darkbg: #{$darkbg};
  --darkSecondary: #{$darkSecondary};
  --darkborder: #{$darkborder};
}

* {
  font-family: "Poppins", sans-serif !important;
}

html {
  height: 100%;
}

body {
  font-size: 16px;
  color: $black !important;
  height: 100%;
  background-color: $lightbluebg !important;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer */
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

a {
  text-decoration: none !important;
  color: $primary !important;
}

p,
a {
  word-break: break-word;
}

// .sidebar-sticky {
//     position: sticky;
//     top: 0;
// }

.MuiDivider-root {
  border-color: #dedede !important;
}

.MuiTable-root .MuiTableCell-root {
  color: $black;
}

.MuiTableContainer-root {
  box-shadow: none !important;

  .MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 15px;
  }

  .MuiTableCell-root {
    border: 1px solid #f2f2f2;

    &:first-child {
      border-radius: 16px 0px 0px 16px;
      border-right: none !important;
    }

    &:nth-child(2) {
      border-left: none !important;
      border-right: none !important;
    }

    &:last-child {
      border-radius: 0 16px 16px 0;
      border-left: none !important;
    }
  }
}

.MuiDialog-paperWidthSm .MuiFormControlLabel-root .MuiFormControlLabel-label {
  position: relative;
  top: -7px;
}

.MuiDialog-paperWidthSm .MuiRadioGroup-root .MuiFormControlLabel-label {
  top: 0;
}

.underline {
  text-decoration: underline !important;
}

:focus-visible {
  outline: none !important;
}

.text-nowrap {
  text-wrap: nowrap;
}

.slick-prev {
  left: 0 !important;
}

.slick-next {
  right: 0 !important;
}

.disable {
  color: #bebebe;
}

.wd-24rem {
  width: 24rem;
}

.text-red-600 {
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.dark-btn {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}

.transform-90deg {
  transform: rotate(90deg);
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

.MuiMasonry-root {
  margin: 0 !important;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.MuiGrid-container {
  margin-top: 0 !important;
  width: 100% !important;
  margin-left: 0 !important;
}

.MuiInputAdornment-outlined {
  img {
    cursor: pointer;
  }
}

// .MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper {
//     max-height: 230px;
// }

.MuiAvatarGroup-root {
  flex-direction: inherit !important;
  margin-left: 20px;
}

.MuiPaper-elevation {
  color: $black !important;
}

.MuiDrawer-paper {
  color: $black !important;
}

.MuiDrawer-root.MuiDrawer-modal {
  z-index: 1301;
}

.MuiCheckbox-colorPrimary {
  color: rgb(102 102 102 / 32%) !important;
}

.h-14 {
  height: 3.5rem !important;
}

.min-w-55px {
  min-width: 55px;
}

.hw-100px {
  height: 100px;
  width: 100px;
  min-width: 100px;
  border-radius: 10px;
  overflow: hidden;
}

.h-235px {
  height: 235px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 16px;
}

.h-250px {
  height: 250px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 16px;
}

.w-224px {
  width: 224px;
  margin: auto;
  display: block;
  object-fit: cover;
  overflow: hidden;
}

.w-32x32 {
  width: 32px;
  height: 32px;
}

// .h-full {
//     height: 100% !important;
// }

.hw-18px {
  height: 18px !important;
  width: 18px !important;
  min-width: 18px !important;
  min-height: 18px !important;
}

.hw-46px {
  height: 46px !important;
  width: 46px !important;
  min-width: 46px !important;
  object-fit: cover;
}

.hw-50px {
  height: 50px !important;
  min-width: 50px !important;
  width: 50px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
}

.hw-121px {
  height: 121px;
  width: 121px;
  min-width: 121px;
  border-radius: 16px;
  overflow: hidden;
  object-fit: cover;
}

.w-22px {
  width: 22px;
}

.min-w-30px {
  min-width: 30px;
}

.w-30x30 {
  width: 30px;
  min-width: 30px;
  height: 30px;
  // border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.h-400px {
  height: 400px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 16px;
}

.h-560px {
  height: 560px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 16px;
}

.h-165px {
  height: 165px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 16px;
}

.h-275px {
  height: 275px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 16px;
  width: 100%;
}

.hw-90px {
  min-height: 120px;
  object-fit: cover;
  border-radius: 10px;
}

.h-215px {
  height: 215px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 16px;
}

.max-h-215px {
  max-height: 215px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 16px;
}

.max-h-235px {
  max-height: 235px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 16px;
}

.min-w-6 {
  min-width: 1.5rem !important;
}

.h-195px {
  height: 195px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 16px;
}

.hw-105px {
  height: 105px;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px;
}

.h-85px {
  height: 85px;
}

.h-455px {
  height: 455px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 16px;
}

.wh-48x48 {
  width: 48px !important;
  height: 48px !important;
}

.MuiIconButton-sizeSmall {
  margin-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #eee;
}

::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

// .MuiTabs-scroller {
//     // overflow: auto !important;
// }

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.h-calc-200 {
  // min-height: calc(100vh - 80px);
  min-height: 700px;
  width: 100%;
}

.w-fit {
  width: fit-content !important;
}

.h-144px {
  height: 144px !important;
}

.w-144px {
  width: 144px !important;
}

.hw-44px {
  height: 44px !important;
  width: 44px !important;
  min-width: 44px !important;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.hw-60px {
  height: 60px !important;
  width: 60px !important;
  min-width: 60px !important;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.hw-38px {
  height: 38px !important;
  width: 38px !important;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.min-w-170px {
  min-width: 170px !important;
  object-fit: cover;
}

.min-w-205px {
  min-width: 205px !important;
  object-fit: cover;
}

.min-h-215px {
  min-height: 215px !important;
}

.min-h-250px {
  min-height: 215px !important;
  object-fit: cover;
}

.gap-3 {
  gap: 0.2rem 0.75rem !important;
}

.gray-border {
  border: 1px solid #f2f2f2 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: $primary !important;
}

.lightgaybg-100 {
  background-color: #fdfdfd;
}

.activenow p {
  background: #eff8fe;
  padding: 5px 20px;
  border-radius: 10px;
  text-align: center;
}

.separator {
  display: flex;
  align-items: center;

  .line {
    height: 1.6px;
    flex: 1;
    background-color: $bordercolor;
  }

  p {
    padding: 0 1rem;
    color: $gray500;
    font-weight: 500;
  }
}

.para {
  color: $secondary;
  text-align: center;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-b {
  border-bottom: 1px solid #eee;
}

.MuiGrid-item {
  padding: 0 !important;
}

.light-blue-search {
  .MuiOutlinedInput-input {
    padding: 15.5px 15px !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 16px !important;
    background: $lightbluebg !important;
    border: 1.6px solid $lightbluebg !important;
  }
}

.MuiRadio-colorPrimary.Mui-checked {
  color: $primary !important;
}

.MuiFormControlLabel-label {
  width: 100%;
}

.MuiRadio-colorPrimary .MuiSvgIcon-fontSizeMedium {
  height: 18px !important;
  width: 18px !important;
  font-size: 1rem !important;
}

.w-max-content {
  width: max-content;

  @media (max-width: 550px) {
    width: auto;
  }
}

.profile-post-btns .min-h-40px,
.profile-post-btns .min-h-40px:hover {
  min-height: 40px !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.top1 {
  position: relative;
  top: 1px;
}

.left4 {
  position: relative;
  left: 4px;
}

.text-end {
  text-align: right !important;
}

.font-8 {
  font-size: 8px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-38 {
  font-size: 38px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-45 {
  font-size: 45px !important;
}

.font-50 {
  font-size: 50px !important;
  line-height: 64px;
}

.w-400px {
  width: 400px;
}

.h-64px {
  height: 64px;
}

.rounded-2xl {
  border-radius: 1rem !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #eaeaea;
}

.DialogTitle h3 {
  text-transform: uppercase;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.text-current {
  color: currentColor !important;
}

.separator {
  display: flex;
  align-items: center;
  margin: 40px auto;
  width: 80%;

  .line {
    height: 1px;
    flex: 1;
    background-color: #d9d9d9;
  }

  p {
    padding: 10px 30px;
    color: #7b7b7b;
    background-color: #eeeeee;
    border-radius: 40px;
    font-weight: 600;
    margin: 0 20px;
  }
}

// input css start
.MuiOutlinedInput-root {
  border-radius: 16px !important;
  background: $white !important;
  border: 1.6px solid $bordercolor !important;
}

button:focus {
  outline: 1px dotted;
  outline: none;
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: $primary !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $primary !important;
}

.MuiInputBase-colorPrimary.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: $red !important;
}

.MuiOutlinedInput-input {
  padding: 20.5px 15px !important;
}

.without-lable-txtbox {
  .MuiOutlinedInput-notchedOutline span {
    display: none;
  }

  .MuiOutlinedInput-input {
    padding: 20.5px 15px 20.5px 0px !important;
  }

  .MuiOutlinedInput-root {
    padding-left: 20px !important;
  }
}

// input css end

// button css start
.MuiButton-containedPrimary {
  box-shadow: none !important;
  background: $primary !important;
  color: $white !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  // font-family: "Poppins", sans-serif !important;
  letter-spacing: 1px !important;
  text-transform: none !important;
  min-height: 65px !important;
  border-radius: 16px !important;
  transition: 0.5s;
  border: 1.6px solid $primary !important;

  @media (max-width: 500px) {
    font-size: 13px !important;
    min-height: 50px !important;
  }
}

.MuiButton-containedPrimary:hover {
  background: $white !important;
  color: $primary !important;
}

.MuiButton-containedPrimary.Mui-disabled {
  background: #e2f4ff !important;
  color: #a2bed2 !important;
  border: 1.6px solid #e2f4ff !important;
}

.MuiButton-outlinedPrimary {
  box-shadow: none !important;
  background: $white !important;
  color: $darkblack !important;
  border: 1.6px solid $bordercolor !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  // font-family: "Poppins", sans-serif !important;
  letter-spacing: 1px !important;
  text-transform: none !important;
  min-height: 65px !important;
  border-radius: 16px !important;

  @media (max-width: 500px) {
    font-size: 13px !important;
    min-height: 50px !important;
  }
}

.light-blue-btn-icon {
  background: $lightbluebg !important;
  color: $primary !important;
  border-radius: 9px !important;
  min-height: 32px !important;
  min-width: 32px !important;
  width: 32px;
  border: none !important;

  &.MuiButton-outlined:hover,
  &.MuiButton-outlined.active {
    border: none !important;
  }

  @media (max-width: 500px) {
    font-size: 13px !important;
    min-height: 50px !important;
  }
}

.light-blue-icon-outline.MuiButton-outlinedPrimary {
  background: transparent;
  color: #2799f6 !important;
  border: 1.6px solid #2799f6 !important;
}

.light-blue-icon-btn {
  height: 40px;
  width: 40px;
  min-width: 40px !important;
  min-height: 40px !important;
  border-radius: 10px !important;
  padding: 0 !important;
  background: $lightbluebg !important;
  border: 1px solid $lightbluebg !important;

  @media (max-width: 500px) {
    font-size: 13px !important;
    min-height: 50px !important;
  }
}

.btn-outline-primary {
  border: 1px solid $primary !important;
  color: $primary !important;

  @media (max-width: 500px) {
    font-size: 13px !important;
    min-height: 50px !important;
  }
}

.green-btn {
  background: $green !important;
  border: 1px solid $green !important;
  color: $white;
  height: 47px;
  font-size: 18px !important;
  font-weight: 500 !important;
  border-radius: 16px !important;
  transition: 0.5s;

  @media (max-width: 500px) {
    font-size: 13px !important;
    min-height: 50px !important;
  }
}

.green-btn:hover {
  background: $white !important;
  color: $green !important;
}

.red-btn {
  background: $red !important;
  border: 2px solid $red !important;
  color: $white;
  height: 47px;
  font-size: 18px !important;
  font-weight: 500 !important;
  border-radius: 16px !important;
  transition: 0.5s;

  @media (max-width: 500px) {
    font-size: 13px !important;
    min-height: 50px !important;
  }
}

.red-btn:hover {
  background: $white !important;
  color: $red !important;
}

.lightcoffee-btn {
  background: $lightcoffee !important;
  border: 1px solid $lightcoffee !important;
  color: $white;
  height: 47px;
  font-size: 18px !important;
  font-weight: 500 !important;
  border-radius: 16px !important;
  transition: 0.5s;

  @media (max-width: 500px) {
    font-size: 13px !important;
    min-height: 50px !important;
  }
}

.lightcoffee-btn:hover {
  background: $white !important;
  color: $lightcoffee !important;
}

.light-blue-btn,
.light-blue-btn:hover {
  background: $lightbluebg !important;
  color: $primary !important;
  border-radius: 16px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  min-height: 49px !important;
  border: none !important;

  @media (max-width: 500px) {
    font-size: 13px !important;
    min-height: 50px !important;
  }
}

.light-red-btn,
.light-red-btn:hover {
  background: #ffe9ed !important;
  color: $red !important;
  border-radius: 16px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  min-height: 47px !important;
  min-width: 47px !important;
  border: none !important;

  @media (max-width: 500px) {
    font-size: 13px !important;
    min-height: 50px !important;
  }
}

.white-shadow-btn {
  box-shadow: 0 0 30px rgb(0 0 0 / 7%) !important;
  border: none !important;
}

.white-btn {
  border: 1px solid #e5f4ff !important;
  color: $black !important;
  background-color: $white !important;

  &:hover img {
    filter: brightness(0) invert(1);
  }
}

.white-btn:hover {
  border: 1px solid $black !important;
  color: $white !important;
  background-color: $black !important;
}

.whitebtn,
.whitebtn:hover {
  border: 1px solid $white !important;
  color: $black !important;
  background-color: $white !important;
  padding: 9px 23px !important;
  border-radius: 10px !important;
  font-size: 16px !important;
}

.offwhitebtn,
.offwhitebtn:hover {
  border: none !important;
  color: $white !important;
  background-color: #ffffff29 !important;
  padding: 9px 10px !important;
  border-radius: 10px !important;
  font-size: 14px !important;
}

.light-gray-btn {
  height: 40px;
  min-height: 40px !important;
  background-color: $lightgay700 !important;
  border: 1px solid $lightgay700 !important;
  min-width: 40px !important;
  padding: 6px 10px !important;
  color: $black !important;
}

.disabled,
.disabled:hover {
  background-color: #e9e9e9 !important;
  color: $darkblue !important;
  border: 1px solid #e9e9e9 !important;
}

// button css end
.text-blue {
  color: $blue !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-black {
  color: $black !important;
}

.text-purple {
  color: #765ea0;
}

.text-darkyellow {
  color: #bab310;
}

.text-green {
  color: #1fba95;
}

.text-darkorange {
  color: #d86e70;
}

.text-darkblue {
  color: $darkblue !important;
}

.light-gray-bg {
  background-color: $lightgraybg;
}

.light-gray700-bg {
  background-color: $lightgay700;
}

.light-blue-bg {
  background-color: $lightbluebg;
}

.light-blue-bg-400 {
  background-color: #48aafa;
}

.light-blue-bg-500 {
  background-color: #f2f8fc;
}

.light-blue-bg-700 {
  background-color: #eef7ff;
}

.lightgray-400 {
  background-color: $lightgray400;
}

.primary-bg {
  background-color: $primary;
}

.light-red-bg {
  background-color: #fef5f4;
}

.text-red,
.text-red:hover {
  color: $red !important;
}

.text-green {
  color: $green;
}

.text-light-gray {
  color: #afafaf;
}

.text-gray-800 {
  color: #d8d8d8;
}

.text-lightgray-300 {
  color: $lightgray300;
}

// auth page css start
.auth-page {
  height: 100%;

  .auth-bg {
    // position: fixed;
    // top: 0;
    // width: 66.666667%;
    background: url(./../images/auth-login.png);
    background-size: cover;
    height: 100%;
    top: 0;
    bottom: 0;

    @media (max-width: 899px) {
      display: none;
    }
  }

  h1 {
    font-size: 30px;
    color: $darkblue;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .OTP-page .MuiOutlinedInput-input {
    text-align: center;
  }
}

// auth page css end

// header css start
.MuiAppBar-colorPrimary {
  background-color: $white !important;
  color: $black !important;
  box-shadow: none !important;

  .MuiToolbar-regular {
    height: 76px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .MuiInputBase-input {
    height: 38px;
  }

  .css-96uuyl {
    background-color: $lightbluebg !important;
    border-radius: 16px;
    margin-right: 10px;
  }
}

.MuiIconButton-sizeLarge {
  background-color: $lightbluebg !important;
  border-radius: 16px !important;
}

// header css end

// sidebar css start
.sidebar {
  position: relative;
  background: transparent !important;
  border-right: 0 !important;
  margin-bottom: 20px;

  .sidebar-sticky {
    position: sticky; 
    top: 0; 
    height: 100vh; 
    overflow-y: auto; 
  }

  .MuiListItemButton-root {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  .MuiListItemButton-root.MuiButtonBase-root {
    margin-right: 20px;

    &.active,
    &:hover {
      background-color: $lightbluebg;
      border-radius: 0 16px 16px 0;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-right: 4px solid $primary;
        height: 20px;
        border-radius: 10px;
        right: 0;
      }
    }

    &.active .MuiListItemText-root {
      font-weight: 600;
      color: $primary;
    }

    .MuiListItemIcon-root {
      min-width: 40px;
    }

    .MuiListItemText-primary {
      font-size: 18px;
    }
  }

  .MuiButton-containedPrimary {
    min-height: 47px !important;
  }

  .badge {
    background: $primary;
    color: $white;
    border-radius: 30px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sidebar-user-box {
  img {
    height: 60px;
    width: 60px;
  }

  h3 {
    color: $darkblue;
    font-size: 18px;
    font-weight: 600;
  }
}

// sidebar css end
.post-save .MuiOutlinedInput-root {
  border-radius: 16px !important;
  background: $lightbluebg !important;
  border: 1.6px solid #c0c0c0 !important;
}

.MuiPopover-paper.MuiMenu-paper {
  border-radius: 16px;
  box-shadow: 0 0 40px rgb(0 0 0 / 8%);

  .MuiMenuItem-root:hover {
    background-color: $white;
    color: $primary;
  }

  .MuiMenuItem-root.Mui-selected {
    background-color: $white;
    color: $primary;
  }
}

.MuiFormControlLabel-root {
  margin-left: 0 !important;
  margin-right: 0 !important;

  .MuiSwitch-track {
    border-radius: 30px;
  }

  &.black-border {
    .MuiSwitch-thumb {
      border: 1.5px solid $black200;
    }

    .MuiSwitch-track {
      border: 1.5px solid $black200;
    }
  }

  .MuiSwitch-thumb {
    position: relative;
    top: -4px;
    left: -2px;
    box-shadow: none;
    height: 10px;
    width: 10px;
    border: 1.5px solid $primary;
    background-color: $lightbluebg;
  }

  .MuiSwitch-root.MuiSwitch-sizeMedium {
    width: 26px;
    height: 21px;
    padding: 0 !important;
  }

  .Mui-checked .MuiSwitch-thumb {
    left: -18px;
    border: 1.5px solid $primary;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: $lightbluebg !important;
    opacity: 1 !important;
  }

  .MuiSwitch-input {
    left: 0 !important;
    width: 100% !important;
  }

  .MuiSwitch-track {
    background-color: $lightbluebg;
    opacity: 1;
    border: 1.5px solid $primary;
  }

  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    border: 1.5px solid $primary;
  }
}

// tab css start
.post-tab {
  .MuiTabs-flexContainer {
    background: $lightbluebg;
    border-radius: 10px;
    width: max-content;

    @media (max-width: 1200px) {
      width: auto;
      flex-wrap: wrap;
    }
  }

  .MuiTab-textColorPrimary {
    font-size: 16px;
    font-weight: 500;
    text-transform: inherit;
    color: $primary;
    border-radius: 10px;

    &.Mui-selected {
      background-color: $primary !important;
      color: $white;
      border-radius: 10px;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
}

// tab css end

// Home css start
.creator-home-module {
  .blue-bg {
    background: $primary;
    min-width: 88px;
    height: 115px;
    border-radius: 16px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .light-blue-bg {
    background: $lightbluebg;
    min-width: 88px;
    height: 115px;
    border-radius: 16px;
    padding: 10px;
    display: flex;
    align-items: center;
    // justify-content: center;
  }

  .discount-bg {
    background: $white;
    padding: 12px;
    border-radius: 50%;
    margin: auto;
  }

  .user-img {
    min-width: 88px;
    height: 115px;
  }

  .profile-images {
    .user-img img:first-child {
      border-radius: 16px;
      height: 115px;
      width: 88px;
      object-fit: cover;
      overflow: hidden;
    }
  }

  .user-position {
    height: 32px;
    width: 32px;
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 50%;
  }

  .user-position.active {
    border: 3px solid $primary;
  }

  .user-text {
    position: absolute;
    bottom: 15px;
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    width: 90%;
    margin: 10% 0;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .user-text2 {
    margin-bottom: 0;
    font-size: 14px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // width: 100%;
    text-align: center;
    width: 88px;
  }

  .shadowsm {
    box-shadow: 0 0 5px rgb(0 0 0 / 9%);
  }

  .MuiFormControl-root.MuiTextField-root {
    background-color: $lightbluebg;
    border-radius: 0px 0px 0px 16px !important;
    height: 65px;
    width: 100%;

    .MuiOutlinedInput-input {
      background: $lightbluebg !important;
      border-radius: 0 0 0px 16px;
    }

    .MuiOutlinedInput-root {
      border: none !important;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }
  }

  .MuiButton-containedPrimary {
    // border-radius: 0px 0px 16px 0px !important;

    // &:hover {
    //     background: $primary !important;
    // }
    &:hover img {
      filter: brightness(1) invert(1);
    }
  }

  .varified {
    .verified-bg {
      position: absolute;
      right: -4px;
      bottom: -5px;
      background: $white;
      border-radius: 50%;
      padding: 5px;
      height: 30px;
      width: 30px;
    }

    h3 {
      color: $darkblue;
      font-size: 18px;
      font-weight: 800;
    }

    p {
      font-size: 14px;
      color: $black;
    }
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    background: rgb(255 255 255 / 32%);
    border-radius: 50%;
    padding: 5px;
  }

  .MoreSquareWhite {
    background-color: #6d645b;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    right: 20px;
    padding: 5px;

    button.MuiIconButton-root {
      padding: 0 !important;
      margin-left: 0 !important;
    }
  }

  .thumbnail-text {
    // background: rgb(30 64 92 / 32%);
    // backdrop-filter: blur(6px);
    padding: 10px;
    position: absolute;
    width: 100%;
    bottom: 0;
    border-radius: 0px 0px 16px 16px;
    z-index: 2;
    overflow: auto;

    &::after {
      content: "";
      background: rgb(30 64 92 / 32%);
      backdrop-filter: blur(6px);
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 1;
    }

    .thumbnail-block {
      position: relative;
      z-index: 2;
    }

    .thumbnailuser1 {
      border: 3px solid $primary;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      object-fit: cover;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
    }

    p {
      font-size: 12px;
    }

    .btn-white.MuiButton-containedPrimary {
      background-color: $white !important;
      border-color: $white !important;
      color: $darkblue !important;
      border-radius: 10px !important;
      min-height: 33px !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      height: 35px;
    }
  }
}

.subscription {
  .light-blue-bg {
    background: $lightbluebg;
    padding: 10px 15px;
    height: auto;
  }

  .bluebg {
    background: url(./../images/bluebg.png) no-repeat;
    background-size: cover;
    padding: 16px;
    border-radius: 16px;
  }

  .AddCircleBlue-bg {
    background: $white;
    border-radius: 10px;
    height: 48px;
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gray-dot-width {
    min-width: 6px;
    height: 6px;
  }

  .ppp-img-bg {
    background: #eff8fe;
    border-radius: 16px;
    padding: 30px;
  }
}

// Home css end

// add queue module css start
.add-queue-module {
  .MuiTabs-flexContainer {
    // background: $white;
    border-radius: 10px;
    gap: 15px;
  }

  .MuiTab-textColorPrimary {
    font-size: 16px;
    font-weight: 600;
    text-transform: inherit;
    flex-direction: row;
    gap: 10px;
    min-height: 39px;
    align-items: center;
    background-color: #f4fafe;
    color: $primary;
    border-radius: 10px;

    &.Mui-selected {
      // background: $primary;
      // color: $white;
      color: $primary;
      border-radius: 10px;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiButton-containedPrimary {
    min-height: 47px !important;
    padding: 0 30px !important;
    margin: auto;
  }

  .h-queue {
    height: calc(100% - 300px);
  }
}

// add queue module css end

// dialog css start
.MuiPaper-rounded.MuiDialog-paperWidthSm {
  border-radius: 16px !important;
  width: 540px;
  color: $black !important;

  .MuiDialogTitle-root {
    text-transform: uppercase;
    color: $darkblue;
    font-size: 20px !important;
    font-weight: 600 !important;
    padding: 16px 20px !important;

    .borderBottom {
      border-bottom: 1px solid #eaeaea;
    }
  }

  .MuiTabs-flexContainer {
    border-bottom: 2px solid #f0f0f0;

    .MuiTab-textColorPrimary {
      width: 50%;
      font-size: 18px !important;
      text-transform: capitalize !important;

      &.Mui-selected {
        color: $primary !important;
        font-weight: 600;
      }
    }
  }

  .MuiTabs-indicator {
    background-color: $primary !important;
  }

  .tab-box {
    .MuiBox-root {
      padding: 16px 0 0;
    }

    .MuiFormControl-root.MuiOutlinedInput-input {
      padding: 20px 15px !important;
      height: 24px !important;
    }
  }

  .MuiButton-containedPrimary,
  .MuiButton-outlinedPrimary {
    height: 47px;
    min-height: 47px !important;
    padding: 0 30px !important;
    line-height: 19px;
  }
}

// dialog css end

// fans home css start
.seven-more-bg {
  background: $darkblue;
  color: $white;
  font-size: 16px;
  padding: 12px 21px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 16px 0px 16px 0px;
}

.MuiMenu-list {
  .varified .verified-bg {
    position: absolute;
    right: -4px;
    bottom: -4px;
    background: $white;
    border-radius: 50%;
    padding: 5px;
    height: 22px;
    width: 22px;
  }

  .MuiDivider-fullWidth {
    border-color: #efefef !important;
    margin: 5px auto 5px;
  }

  .MuiMenuItem-root:hover {
    background-color: transparent !important;
  }
}

//fans home css end

// fans profile css start
.fans-profile {
  min-height: 100vh;
  padding-bottom: 80px; // Add padding for bottom navigation
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; // For smooth scrolling on iOS

  @media (max-width: 767px) {
    padding-bottom: 100px; // Increase padding for smaller screens
    height: auto;
    min-height: calc(100vh - 56px); // Account for bottom navigation
  }

  .heading {
    .MuiButton-contained {
      border-radius: 10px;
      min-height: 47px !important;
      min-width: 134px !important;
      line-height: 1.3;

      &:hover img {
        filter: brightness(1) invert(1);
      }
    }
  }

  .post-tab {
    .MuiTab-textColorPrimary {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .MuiTab-textColorPrimary {
      min-height: 47px;
      min-width: 140px;
    }

    .MuiTabs-flexContainer {
      background: transparent;
    }

    .MuiTab-textColorPrimary {
      background: $lightbluebg;
      border-radius: 10px;

      &.Mui-selected {
        background-color: $primary !important;
      }
    }

    .MuiTab-iconWrapper {
      margin-bottom: 0;
    }

    .MuiTabs-flexContainer {
      gap: 15px;
    }
  }

  .profile-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-bottom: 1px solid $primary;
    // width: 201px;
    z-index: 2;
  }

  .profile-user {
    position: absolute;
    top: -55px;
    left: 35px;
    height: 110px;
    width: 110px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    z-index: 2;

    @media (max-width: 550px) {
      top: -22px;
      left: 15px;
      width: 95px;
      height: 95px;
    }
  }

  .camera-btn {
    position: absolute;
    left: 105px;
    bottom: 20px;
    border-radius: 10px !important;
    background: #fff !important;
    height: 36px;
    width: 36px;
    min-height: 36px !important;
    min-width: 36px;
    padding: 3px !important;
    border: none !important;
    z-index: 3;

    @media (max-width: 550px) {
      left: 80px;
      bottom: 14px;
    }
  }

  .MuiInputBase-inputMultiline {
    padding: 0 !important;
  }

  @media (max-width: 767px) {
    p.ml-40 {
      margin-left: 8.5rem;
    }
  }

  @media (max-width: 550px) {
    p.ml-40 {
      margin-left: 6.5rem;
    }
  }

  .account-info {
    .MuiOutlinedInput-root {
      border: none;
      border-radius: 16px !important;
      background: #f4f4f4 !important;
    }

    .MuiOutlinedInput-input {
      padding: 16.5px 14px !important;
      border-radius: 16px !important;
    }

    .MuiInputAdornment-positionEnd img {
      height: 26px !important;
      width: 28px !important;
      max-width: inherit;
      cursor: pointer;
    }
  }
}

.twitter-close {
  position: relative;
  right: 0;
  top: 15px;
}

.warning-icon {
  margin: auto;
  position: absolute;
  top: -42px;
  left: 50%;
  transform: translateX(-50%);
}

// fans profile css end

// profile post css start
.profile-post {
  .profile-post-tag {
    position: absolute;
    left: 20px;
    top: 0;
    color: $white;
    padding: 10px 20px;
    background: $green;
    border-radius: 0 0 16px 16px;

    @media (max-width: 991px) {
      padding: 10px 12px;
      left: 10px;
    }

    @media (max-width: 500px) {
      padding: 6px 10px;

      .font-18,
      .font-16 {
        font-size: 14px !important;
      }
    }
  }

  .available-tag {
    position: absolute;
    right: 20px;
    top: 10px;
    color: $green;
    padding: 6px 20px;
    background: $white;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: 991px) {
      right: 10px;
      padding: 10px 12px;
    }

    @media (max-width: 500px) {
      font-size: 12px;
      padding: 6px 10px;

      .font-14 {
        font-size: 8px !important;
        position: relative;
        top: -2px;
      }
    }
  }

  .profile-user {
    position: relative;
    top: -65px;
    left: 30px;

    @media (max-width: 1024px) {
      height: 90px !important;
      width: 90px !important;
      top: -38px;
      left: 15px;
    }
  }

  @media (max-width: 1024px) {
    .min-w-170px {
      min-width: 155px !important;
    }

    .gap-10 {
      gap: 1.5rem;
    }
  }

  .profile-tabs {
    .MuiTab-textColorPrimary {
      font-size: 16px;
      font-weight: 600;
      width: 33.33%;

      &.Mui-selected {
        color: $primary;
      }
    }

    .MuiTabs-indicator {
      background-color: $primary;
    }
  }

  .profile-gallery-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;

    @media (max-width: 500px) {
      top: 10px;
      right: 12px;
    }
  }

  .profile-lock-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 2;
  }

  .rounded-full {
    border-radius: 50% !important;
  }

  .profile-lock-icon {
    .MuiButton-containedPrimary.rounded-full {
      min-width: 74px !important;
      min-height: 74px !important;

      &:hover img {
        filter: brightness(0) invert(1);
      }
    }

    .unlock-post {
      border-radius: 16px !important;
      padding: 6px 25px !important;

      &:hover {
        background-color: $white !important;
        color: $primary !important;
      }

      @media (max-width: 500px) {
        padding: 6px 15px !important;
      }
    }
  }

  .posttabbtn {
    .MuiTab-labelIcon {
      background-color: $lightbluebg;
      color: $primary;
      border-radius: 10px;
      flex-direction: row;
      display: flex;
      gap: 10px;
      align-items: center;
      min-height: 47px;
      text-transform: capitalize;

      &.Mui-selected {
        background-color: $primary !important;
        color: $white;

        img {
          filter: brightness(0) invert(1);
        }
      }
    }

    .MuiTab-textColorPrimary {
      width: inherit;
    }
  }

  .profile-media {
    .gray-border {
      border: 1px solid #f2f2f2;
      border-radius: 16px;
      padding: 15px;
      margin: 0 7px 14px;
    }

    .rounded-full {
      border-radius: 50% !important;
      min-height: 60px !important;
      min-width: 60px !important;
      width: 60px !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
    }

    .video-play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgb(255 255 255 / 20%);
      border-radius: 50%;
      padding: 7px;
    }
  }

  .MuiTabPanel-root {
    padding: 15px 0;
  }
}

.radiobtn-menu .MuiPopover-paper.MuiMenu-paper {
  width: 200px;
}

.radiobtn-menu .MuiMenu-list .MuiMenuItem-root {
  flex-direction: column;
  align-items: flex-start;
}

//profile post css end

//Streams css start
.streams {
  .lighrgraybg {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 15px;
    margin: 0 7px 14px;
    flex: 1;
  }

  .profile-stream-tag {
    background-color: $red;
    color: #fff;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 7px 12px;
    border-radius: 10px;
  }

  .profile-stream-tag-primary {
    background-color: $primary;
  }

  .card-hover-icon {
    display: none;
    position: absolute;
    bottom: 10px;
    justify-content: center;
    gap: 10px;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.5s;

    .MuiButton-outlinedPrimary {
      background: #beafa1 !important;
      backdrop-filter: blur(5px);
      border: none !important;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .lighrgraybg:hover .card-hover-icon {
    display: flex;
  }
}

//Streams css end

// product details css start
.product-detail {
  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .product-thumbnail .slick-slide {
    padding: 0 5px;
  }

  .video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    cursor: pointer;
    background: rgb(255 255 255 / 22%);
    border-radius: 50%;
    padding: 5px;
  }

  .video-transparent-bg::after {
    content: "";
    position: absolute;
    background-color: rgb(0 0 0 / 60%);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border-radius: 10px;
  }

  .plus-minus-txtbox {
    .MuiOutlinedInput-input {
      padding: 9.5px 15px !important;
      background: #f8f8f8;
      border-radius: 10px;
      font-size: 20px;
      font-weight: 500;
    }

    .MuiOutlinedInput-root {
      border: none;
    }

    .MuiIconButton-sizeMedium {
      background: #f8f8f8;
      border-radius: 10px;
      height: 47px;
      width: 47px;
    }
  }

  .addtocart-btn {
    min-height: 47px !important;
  }

  .MuiAccordion-rounded.MuiAccordion-gutters {
    box-shadow: none;
    margin: 0 !important;
    border-bottom: 1px solid #ececec;

    &::before {
      display: none;
    }

    .MuiAccordionSummary-content {
      margin: 10px 0;
      color: $darkblue;
      font-weight: 600;
    }

    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 45px;
    }

    .MuiPaper-root-MuiAccordion-root.Mui-expanded {
      padding: 0 0 15px !important;
    }

    .MuiAccordionDetails-root,
    .MuiAccordionSummary-root {
      padding: 0;
    }

    .MuiAccordionDetails-root {
      padding: 0 0 15px;
    }
  }
}

// product details css end

// setting drawer css start
.setting-drawer {
  width: 380px;

  @media (max-width: 400px) {
    width: 98%;
  }

  .BlueCircleBg-position {
    position: absolute;
    top: 0;
    padding: 16px;

    @media (max-width: 400px) {
      .mt-4 {
        margin-top: 0.3rem !important;
      }
    }
  }

  .setting-list {
    max-height: calc(100% - 50px);
    overflow: auto;
  }

  .closeIcon {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .MuiListItemButton-gutters {
    margin-right: 0 !important;
    background: #fff;
    box-shadow: 0 0 40px rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin-top: 10px;
  }

  .MuiListItemText-primary {
    font-size: 16px !important;
    color: $darkblue;
  }

  .MuiListItemText-root.text-end .MuiListItemText-primary {
    font-size: 14px !important;
  }

  .offwhitebtn {
    min-height: 41px !important;
  }
}

//setting drawer css end

.MuiFormControlLabel-root {
  align-items: baseline !important;

  .MuiRadio-colorPrimary {
    position: relative;
    top: 3px;
  }
}

// expire-profile css start
.expire-profile {
  .subscription {
    .profile-post-tag {
      left: inherit;
      right: 25px;
    }
  }

  .expire-profile-post-tag {
    background-color: $red !important;
  }
}

.gray-border-box {
  border: 1px solid #f2f2f2;
  border-radius: 16px;
  padding: 10px 15px;
}

// expire-profile css end

.light-blue-bg-500 .MuiFormControlLabel-label {
  color: $primary;
}

// unsubscribe css start
.unsubscribe {
  .MuiPaper-rounded.MuiDialog-paperWidthSm {
    width: 355px;
    background: $primary;

    .MuiDialogTitle-root {
      color: $white;
      padding: 16px 20px !important;
    }

    .MuiDialogContent-root {
      background-color: $white;
      border-radius: 16px;
      padding-top: 20px !important;
    }
  }
}

// unsubscribe css end

// unsubscribe reason css start
.unsubscribe-reason {
  .MuiFormControlLabel-root {
    align-items: center !important;
    display: flex !important;
  }

  .MuiOutlinedInput-input {
    padding: 0 !important;
  }
}

// unsubscribe reason css end

// noarticle css start
.noarticle {
  .profile-tabs .parent-tab .MuiTab-textColorPrimary {
    width: 50%;
  }

  .creator-home-module {
    .slick-slide .thumbnail-text {
      // width: calc(100% - 24px);
      width: 100%;
      border-radius: 0px 0px 10px 10px;
      padding: 5px 10px;
      height: 40px;
      align-items: center;
      display: flex;
    }

    .slick-slide .thumbnailuser1 {
      height: 30px;
      width: 30px;
    }

    .slick-arrow {
      display: none !important;
    }

    .slick-slide {
      padding: 0 8px;
    }
  }

  .MuiAccordion-rounded {
    box-shadow: none !important;

    .MuiAccordionSummary-root {
      min-height: 25px !important;
      padding: 0 !important;
      text-transform: uppercase;

      .MuiAccordionSummary-content {
        margin: 0 0 10px;
        font-size: 18px;
        font-weight: 600;
        color: $darkblue;
      }

      .MuiAccordionDetails-root {
        padding: 8px 0 0 !important;
      }

      .MuiAccordionSummary-expandIconWrapper {
        color: #292d32;
      }
    }

    .MuiAccordionDetails-root,
    .MuiAccordionDetails-root {
      padding: 0 !important;
    }

    .streams {
      .MuiButton-containedPrimary {
        border-radius: 10px !important;
        min-height: 37px !important;
      }

      .spotify-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
      }
    }
  }
}

// noarticle css end

//send tips css start
.send-tips {
  .BlueCirclePosition {
    position: absolute;
    top: 0;
    padding: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;

    @media (max-width: 400px) {
      padding: 6px 15px;

      .uesr1 {
        width: 40px;
        height: 40px;
      }
    }
  }

  .radio-btn-border {
    border: 1px solid #f1f1f1;
    padding: 13px 15px 13px 10px;
    border-radius: 10px;
    margin-bottom: 10px;

    .MuiFormControlLabel-root {
      display: flex;
      align-items: center !important;
      color: $darkblue;

      .MuiFormControlLabel-label {
        font-weight: 500;
      }

      .MuiRadio-colorPrimary {
        top: 0;
      }
    }
  }

  .add-payment-option {
    .MuiButton-outlined {
      border: 2px dashed #d9d9d9 !important;
      min-height: 47px !important;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    bottom: 0;
    top: -20px;
  }

  .slick-dots li {
    width: 30.33%;
    height: 4px;

    button {
      width: 100%;
    }

    button:before {
      width: 100% !important;
      height: 4px;
      content: "";
      background: #f0f0f0 !important;
      opacity: 1;
    }

    &.slick-active button:before {
      background: $primary !important;
    }
  }

  .buttons .MuiButton-outlinedPrimary {
    padding: 0 7px !important;
    min-width: 47px !important;
    width: auto;
  }
}

//send tips css end

// yrsaclick dialog css start
.yrsaclick-dialog {
  .MuiDialogContent-root {
    padding: 0 20px 20px !important;
  }

  .MuiPaper-rounded.MuiDialog-paperWidthSm .MuiDialogTitle-root {
    padding: 16px 20px 5px !important;
  }
}

// yrsaclick dialog css end

// search profile css start
.search-profile {
  .css-96uuyl {
    background-color: #eff8fe !important;
    border-radius: 16px;
    margin-right: 10px;
    margin-left: 0;
    padding: 0 15px;
    width: 100%;
  }

  .MuiInputBase-adornedStart {
    height: 47px;
    width: 100%;
  }

  .search-data .MuiButton-containedPrimary {
    border-radius: 10px !important;
    min-height: 37px !important;
  }

  .lightgray-btn {
    background: #f3f3f3 !important;
    border: 1px solid #f3f3f3 !important;
    border-radius: 20px !important;
    min-height: 31px !important;
    color: $primary !important;
  }
}

// search profile css end

// profile video dialog css start
.profile-video-dialog {
  .MuiDialogContent-root {
    padding: 0 !important;
  }

  .MuiPaper-rounded.MuiDialog-paperWidthSm {
    max-width: 800px;
    width: auto;
  }
}

// profile video dialog css end

// poll fans css start
.poll-fans {
  .account-verified {
    font-size: 18px;
    font-weight: 600;
    border-radius: 16px;
    padding: 8px 15px;
    background-color: #eaf5ff;
    color: $primary;
    display: flex;
    align-items: center;
    gap: 10px;
    width: max-content;

    @media (max-width: 400px) {
      width: auto;
    }
  }

  .poll-data {
    box-shadow: 0 0 10px rgb(0 0 0 / 6%);
    border-radius: 16px;
    padding: 20px;

    .MuiButton-containedPrimary {
      border-radius: 10px !important;
      min-height: 40px !important;
      font-weight: 500 !important;
    }

    .feed-box .MuiButton-containedPrimary {
      border-radius: 0px 0px 16px 0px !important;
    }

    .remove-outline-circle {
      position: absolute;
      right: -5px;
      color: #d3e1ec;
      top: -6px;
      background: #fff;
      padding: 0;
    }

    .add-payment-option {
      .MuiButton-outlined {
        border: 2px dashed #d9d9d9 !important;
        min-height: 48px !important;
      }
    }
  }
}

// poll fans css end

//public-page css start
.public-page {
  .public-box {
    border: 1px solid #f2f2f2;
    padding: 15px;
    border-radius: 16px;
    margin-bottom: 15px;

    .img-height {
      height: 210px;
      width: 100%;
      object-fit: cover;
    }

    .new-tag {
      background-color: $primary;
      padding: 6px 20px;
      color: $white;
      font-weight: 600;
      position: absolute;
      left: 10px;
      top: 0;
      border-radius: 0 0 16px 16px;
      align-items: center;

      @media (max-width: 350px) {
        padding: 6px 10px;
      }
    }

    .connectivity {
      background-color: #3ca70a;
      padding: 6px 20px;
      color: $white;
      font-weight: 600;
      position: absolute;
      right: 10px;
      top: 0;
      border-radius: 0 0 16px 16px;
      align-items: center;
    }
  }
}

//public-page css end
.explorerfans-page {
  .MuiMasonry-root {
    margin: 0 !important;

    // .masonary-box {
    //     // margin: calc(10px / 2) !important;
    //     // width: calc(33.33% - 10px) !important;
    // }
  }

  .massonary-img {
    border-radius: 16px;
    display: block;
    width: 100%;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    background: rgb(255 255 255 / 32%);
    border-radius: 50%;
    padding: 5px;
  }
}

// fans profile css start
.fans-notification {
  .light-gray-box {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }

  .notify-user {
    height: 50px;
    width: 50px;
    min-width: 50px;
    object-fit: cover;
    overflow: hidden;
  }

  .notify-user-status {
    position: absolute;
    bottom: -6px;
    right: -13px;
    background: $white;
    border-radius: 60%;
    padding: 5px;
    width: 28px;
    height: 28px;
  }

  .line {
    color: #d2d2d2;
  }

  .light-blue-btn {
    font-weight: 500 !important;
    border-radius: 10px !important;
    min-height: 37px !important;
    text-align: left;
    background: #e8f1f9 !important;
  }

  // .date-position {
  //     position: absolute;
  //     right: 20px;
  //     width: 75px;
  //     text-align: right;
  //     min-width: 75px;
  // }

  // .title-width {
  //     width: calc(100% - 80px);
  // }
}

// fans profile css end

.title-width {
  width: calc(100% - 25px);
}

.drag-content {
  padding: 10px 15px;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: grab;
}

// fans message css start
.fans-message {
  .left-side {
    .MuiOutlinedInput-root {
      border-radius: 10px !important;
      background: #f8f8f8 !important;
      border: none !important;
      height: 47px;
    }

    .post-tab .MuiTab-textColorPrimary {
      min-height: 39px;
      min-width: auto;
      padding: 0 20px;
    }

    .css-96uuyl {
      background-color: $lightbluebg !important;
      border-radius: 16px;
      margin: 0 !important;

      .MuiInputBase-root {
        height: 54px;
        width: 100%;

        .MuiInputBase-input {
          padding-left: 50px;
          padding-right: 15px;
        }
      }
    }

    .active-user {
      height: 15px;
      width: 15px;
      position: absolute;
      bottom: 1px;
      right: -2px;
      color: $green;
    }

    .post-tab {
      .h-530px {
        height: 530px;
      }
    }

    .message-list {
      background-color: $white;
      padding: 10px 15px;
      border-radius: 10px;

      &:hover {
        background-color: $lightgraybg;
      }

      &.active {
        background-color: $lightgraybg;
      }

      .number-badge {
        height: 24px;
        width: 24px;
        min-width: 24px;
        background-color: $primary;
        color: $white;
        border-radius: 50%;
        text-align: center;
      }
    }

    .creator-user-price {
      font-weight: 600;
      color: #fff;
      padding: 0px 12px;
      border-radius: 10px;
      position: absolute;
      bottom: 0;

      &.green-bg {
        background-color: #3ca70a;
      }

      &.purple-bg {
        background-color: #5a4bad;
      }

      &.red-bg {
        background-color: #f64027;
      }

      &.gray-bg {
        background-color: #e9e9e9;
        color: $black;
      }
    }
  }

  .new-msg-btn.MuiButton-containedPrimary {
    padding: 6px 4rem !important;
    min-height: 47px !important;
  }

  .message-body {
    background-color: $lightgay700;
    padding: 10px 15px;
    border-top: 1px solid #eee;

    .active-user {
      height: 10px;
      width: 10px;
      right: -3px;
    }

    .senderimage {
      max-width: 60% !important;

      @media (max-width: 1300px) {
        max-width: 80% !important;
      }

      // width: 80% !important;
    }

    .sender-msg {
      position: relative;
      max-width: 100%;
      display: inline-block;

      // width: max-content;
      &.blue-bg {
        background: $primary;

        .text-darkblue {
          color: $white !important;
        }

        :before {
          border-color: transparent transparent $primary transparent;
        }
      }

      &::before {
        display: block;
        clear: both;
        content: "";
        position: absolute;
        bottom: 0;
        left: -7px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 12px 15px 12px;
        border-color: transparent transparent $white transparent;
        transform: rotate(0);
      }

      .light-blue-icon-btn {
        border-radius: 50% !important;
      }

      .red-btn,
      .green-btn {
        min-height: 47px !important;
      }
    }

    .replyimage {
      max-width: 64% !important;
      margin-left: auto;

      @media (max-width: 1300px) {
        max-width: 80% !important;
      }

      // width: 80% !important;
    }

    .reply-msg {
      position: relative;
      max-width: 100%;
      // width: max-content;
      display: inline-block;
      margin-left: auto;

      &.blue-bg {
        background: $primary;

        .text-darkblue {
          color: $white !important;
        }

        &:before {
          border-color: transparent transparent $primary transparent;
        }
      }

      &:before {
        display: block;
        clear: both;
        content: "";
        position: absolute;
        bottom: 0;
        right: -7px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 12px 15px 12px;
        border-color: transparent transparent $white transparent;
        transform: rotate(0);
      }

      .light-blue-icon-btn {
        border-radius: 50% !important;
      }

      .red-btn,
      .green-btn {
        min-height: 47px !important;
      }
    }
  }

  .message-footer {
    background-color: $white;
    padding: 8px 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 0px 16px 0px;

    &.bg-primary {
      background-color: $primary;
      color: $white;
      min-height: 63px;
    }

    .MuiOutlinedInput-root {
      background: transparent !important;

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
      }
    }

    .message-width {
      width: calc(100% - 367px);
    }

    .send-btn {
      min-height: 47px !important;
    }
  }

  .select-user-msg {
    background-color: #f4fafe;
    color: $primary;
    padding: 15px 30px;
    font-size: 24px;
    border-radius: 10px;
  }

  .reject-btn {
    background-color: $white !important;
    color: $red !important;
    border: none !important;
    min-height: 47px !important;
  }
}

// fans message css end

// chat gallery css start
.chat-gallery {
  .profile-lock-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .unlock-post {
      min-height: 38px !important;
      font-size: 13px !important;
      padding: 7px 15px 6px !important;
    }

    .white-btn {
      border-radius: 50% !important;
      min-height: 56px !important;
      min-width: 56px !important;
      width: 56px !important;

      img {
        height: 32px !important;
        width: 32px !important;
        min-width: 32px;
      }
    }
  }

  .gray-border .light-blue-icon-btn {
    height: 24px;
    width: 24px;
    min-width: 24px !important;
    min-height: 24px !important;
    border-radius: 5px !important;
  }

  .thumbnailuser1 {
    border: 3px solid $primary;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    object-fit: cover;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

// chat gallery css end

// collectionsFans css start
.collectionsFans {
  .recent-txtbox {
    .MuiOutlinedInput-input {
      padding: 11.5px 15px !important;
    }

    .MuiOutlinedInput-root {
      border-radius: 16px !important;
      background: #f8f8f8;
      border: none;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }
  }

  .light-gray-bg {
    background-color: #f8f8f8;
    border-radius: 16px;
    padding: 16px;

    .h-100px {
      height: 100px;
    }

    .h-77px {
      height: 77px !important;
      object-fit: cover;
      overflow: hidden;
    }

    .w-77px {
      width: 77px !important;
    }

    .profile-user {
      bottom: -43px;
      top: inherit;
      left: 15px;
      border: 3px solid $white;
    }
  }

  .min-h-40px {
    min-height: 40px !important;
  }

  .profile-post-btns .light-blue-icon-btn {
    height: 34px;
    width: 34px;
    min-width: 34px !important;
    min-height: 34px !important;
  }

  .available-tag {
    position: absolute;
    right: 20px;
    top: 10px;
    color: $green;
    padding: 6px 20px;
    background: $white;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: 991px) {
      right: 10px;
      padding: 10px 12px;
    }

    @media (max-width: 500px) {
      font-size: 12px;
      padding: 6px 10px;

      .font-14 {
        font-size: 8px !important;
        position: relative;
        top: -2px;
      }
    }
  }

  .customer-box {
    border: 1px solid #ebebeb;
    border-radius: 10px;
    padding: 15px;

    &.active {
      background-color: $primary;
      color: $white;

      .text-darkblue {
        color: $white !important;
      }

      .img-filter-active {
        filter: brightness(0) invert(1);
      }
    }
  }

  .avatar-img {
    width: 50px;
    height: 30px;
    margin-left: -21px !important;
    border-radius: 30px !important;
    border: 1px solid #fff !important;
  }

  .chkbox-position {
    position: absolute;
    top: -5px;
    right: -7px;
  }

  .friend-box {
    &.active {
      border: 1px solid $primary !important;

      .MuiCheckbox-colorPrimary.Mui-checked {
        color: #fff !important;
      }

      .checkbox-bg {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0;
        width: 50px;
        height: 60px;
        border-left: solid 50px transparent;
        border-bottom: solid 50px transparent;
        border-top: solid 50px $primary;
      }
    }
  }

  .profile-media .light-blue-icon-btn {
    min-width: 22px !important;
    min-height: 24px !important;
    border-radius: 5px !important;
    width: 22px;
    height: 24px;
  }

  .bluebg {
    background: url(./../images/blue-bg-subscription.png) no-repeat;
    background-size: cover;
    padding: 16px 25px;
    border-radius: 16px;
  }
}

// collectionsFans css end

// subscription plan css start
.subscription-plan {
  .MuiDialogContent-root {
    padding: 0 !important;
  }

  .close-icon {
    position: absolute !important;
    right: 10px;
    top: 10px;
    background: rgb(255 255 255 / 20%);
    border-radius: 10px;
    backdrop-filter: blur(6px);
  }

  .sub-plan-bg {
    background-color: $white;
    border-radius: 60px 60px 16px 16px;
    padding: 30px 30px 20px;
    position: relative;
    margin-top: -60px;
    max-height: calc(100vh - 315px);
    overflow: auto;

    .MuiAccordionSummary-content {
      margin-top: 25px;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }

    .bg-primary {
      background-color: $primary;
      color: $white;
      padding: 10px;
      border-radius: 80px;

      .plan-user {
        border-radius: 50%;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }

  .expire-plan {
    .bg-primary {
      background-color: #e9e9e9;
      color: $darkblue;
    }

    .Mui-disabled {
      background-color: #e9e9e9 !important;
      border: 1px solid #e9e9e9 !important;
      color: $darkblue !important;
    }
  }

  .MuiAccordion-rounded {
    box-shadow: none;

    &::before {
      background-color: transparent;
    }

    .MuiAccordionSummary-root {
      min-height: auto;
    }

    .MuiAccordionSummary-content {
      justify-content: center;
      margin-bottom: 0;
    }

    .MuiAccordionDetails-root {
      padding: 0 !important;
    }
  }
}

// subscription plan css end

.ticketRip {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circleLeft {
  width: 12px;
  height: 24px;
  background-color: $white;
  border-radius: 0 12px 12px 0;
}

.ripLine {
  width: 100%;
  border-top: 3px dashed #e7e7e7;
}

.circleRight {
  width: 12px;
  height: 24px;
  background-color: $white;
  border-radius: 12px 0 0 12px;
}

.shadow-none {
  box-shadow: none !important;
}

// payment css start
.payment-fans {
  .MuiSelect-select {
    display: flex;
    gap: 10px;
  }

  .MuiCheckbox-sizeMedium {
    position: relative;
    top: 7px;
  }

  .min-h-47px {
    min-height: 47px !important;
  }

  .min-h-54px {
    min-height: 54px !important;
  }

  .light-blue-bg {
    background-color: #f4fafe;
    padding: 15px 30px;
    border-radius: 16px;
  }

  .MuiButton-outlinedPrimary {
    color: #2799f6 !important;
    border: 1.6px solid #2799f6 !important;
  }

  .payment-icons {
    background-color: #f8f8f8 !important;
    border-radius: 10px !important;
    border: 1px solid #f8f8f8 !important;
    min-height: 40px !important;
    width: 96px;
  }

  .MuiFormControlLabel-root .MuiRadio-colorPrimary {
    padding-top: 0;
    padding-bottom: 0;
  }

  .credit-card-radio {
    .MuiFormControlLabel-label {
      font-size: 20px;
      color: $darkblue;
      font-weight: 500;
    }

    .MuiRadio-colorPrimary .MuiSvgIcon-fontSizeMedium {
      height: 25px !important;
      width: 25px !important;
      font-size: 1rem !important;
    }

    .MuiFormControlLabel-root .MuiRadio-colorPrimary {
      top: 6px;
      margin-right: 5px;
    }
  }

  .credit-card-radio.send-tips
    .MuiFormControlLabel-root
    .MuiRadio-colorPrimary {
    top: 1px;
  }
}

// .parentClass .MuiPopover-paper.MuiMenu-paper {
//     max-height: 300px;
// }

.w-750px {
  width: 750px;
  padding: 30px;

  @media (max-width: 800px) {
    width: 98%;
    padding: 15px;
  }

  .MuiPopover-paper.MuiMenu-paper {
    max-height: 300px;
  }
}

// payment css end

// cart css start
.cart-fans {
  .plus-minus-textbox {
    display: flex;

    .MuiButton-root {
      height: 35px;
      width: 35px;
      min-height: 35px !important;
      min-width: 35px !important;
      border-radius: 10px !important;
      padding: 6px 6px !important;
      background-color: #f8f8f8 !important;
      color: #292d32 !important;
    }

    .MuiInputBase-root {
      border-radius: 10px !important;
      border: 1px solid #f8f8f8 !important;

      .MuiInputBase-input {
        height: 32px !important;
        min-height: 32px !important;
        padding: 0px !important;
        border-radius: 10px !important;
        padding: 0.5px 7px !important;
        background: #f8f8f8 !important;
      }
    }
  }

  .red-close-icon-position,
  .red-close-icon-position:hover {
    background-color: rgba(239, 83, 80, 0.1);
    border-radius: 0 16px 16px 0 !important;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    min-width: 36px;
  }

  .plus-minus-width {
    padding-right: 50px;
  }

  .border-b {
    border-bottom-color: #eee;
  }

  .border-b-dashed {
    border-bottom: 2px dashed #e2e2e2;
  }
}

// cart css end

// thank you modal css start
.thankyou-modal {
  .blue-bg {
    background-color: $primary;
    padding: 20px;
    border-radius: 16px;
    color: $white;
  }
}

// thank you modal css end

// my order css start
.my-order {
  .blue-bg {
    background-color: $primary;
    color: $white;
    padding: 15px 20px;
  }
}

// my order css end

// request photo modal css start
.request-photo-modal {
  .plus-minus-textbox {
    border: 1px solid #f0f0f0;
    padding: 5px;
    border-radius: 10px;

    .MuiInputBase-root .MuiInputBase-input {
      background: transparent !important;
      font-weight: 600;
    }

    .MuiButton-root {
      background-color: #eef7ff !important;
      color: $primary !important;
    }
  }

  .MuiInputBase-inputMultiline {
    padding: 0 !important;
  }
}

// request photo modal end

.border-left-height {
  position: relative;
  margin-left: 16px;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    position: relative;
    display: flex;
    border-left: 4px solid $primary;
    height: 30px;
    width: 4px;
    border-radius: 0 10px 0 10px;
    left: -16px;
    top: -3px;
  }
}

.payment-successfully {
  background-color: #f6ffea;
  padding: 15px;
  border-radius: 16px;
  color: #3ca70a;
}

// order detail css start
.w-675px {
  max-width: 675px;
}

.order-detail {
  padding: 0;

  .payment-text {
    background-color: #3ca70a;
    color: $white;
    padding: 2px 10px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
  }

  .inprogress-text {
    background-color: transparent;
    color: #c7a718;
    border: 1px solid #c7a718;
    padding: 2px 10px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    white-space: nowrap;
  }

  .MuiTableContainer-root .MuiTableCell-root {
    border: none !important;
    padding: 0px 16px;
  }

  .MuiTableCell-head {
    font-size: 20px;
    color: inherit;
  }

  .order-detail-tbl {
    .MuiTableContainer-root .MuiTableCell-root {
      border: 1px solid #f2f2f2 !important;
      padding: 16px;
    }

    .MuiButton-containedPrimary {
      background: #f8f8f8 !important;
      color: $darkblue !important;
    }

    .MuiTableContainer-root .MuiTableCell-root:nth-child(2) {
      border-left: none !important;
      border-right: none !important;
    }

    .MuiTableContainer-root .MuiTableCell-root:first-child {
      border-right: none !important;
    }

    .MuiTableContainer-root .MuiTableCell-root:last-child {
      border-left: none !important;
    }
  }

  .min-h-39px {
    min-height: 39px !important;
    height: 39px;
  }

  .MuiTimelineItem-positionRight {
    &::before {
      display: none;
    }

    .MuiTimelineConnector-root {
      width: 4px;
      background-color: #eaeaea;
      border-radius: 10px;
    }

    .MuiTimelineDot-filled {
      border-color: #eaeaea;
      color: #fafafa;
      background-color: #fff;
      border-width: 3px;
      padding: 7px;
    }

    .MuiTimelineDot-filled.blue {
      border-color: $primary;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        background-color: $primary;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .MuiTimelineContent-positionRight {
      padding: 6px 16px 20px;
    }
  }
}

// order detail css end

// profile creator block country modal
.block-country-modal {
  .border-left-4px {
    border-left: 4px solid $primary;
    border-radius: 40px;
    margin-right: 10px;
  }
}

.MuiMenu-list .MuiMenuItem-root {
  // width: 95% !important;
  margin: auto !important;
  // min-height: 54px !important;
  border-radius: 10px !important;
  gap: 10px;
}

.MuiMenu-list .MuiMenuItem-root:hover {
  background-color: #eff8fe !important;
}

// profile creator block country modal

// subscription-profile css start
.subscription-profile {
  .min-h-47px {
    min-height: 47px !important;
  }

  .subscription-img {
    min-height: 150px;
  }

  .subscription-text {
    position: absolute;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    width: 100%;
  }

  .subscription-user-position {
    position: relative;
    top: -35px;
    margin-left: 20px;
    width: 78px;
    height: 78px;
    min-width: 78px;

    @media (max-width: 1300px) {
      top: -16px;
    }

    @media (max-width: 767px) {
      top: 10px;
      margin-bottom: 25px;
    }
  }
}

// subscription-profile css end

// qr code modal css start
.qr-code-modal {
  .blue-bg {
    background-color: $primary;
    padding: 20px;
    border-radius: 22px;

    .qr-code-img {
      max-width: 325px;
      margin: auto;

      @media (max-width: 450px) {
        max-width: 95%;
      }
    }

    .msg-box {
      border: 1.36px solid #52c4ff;
      border-radius: 20px;
      padding: 10px 15px;
      color: #fff;
      background: #31a3f5;
      min-height: 68px;
    }
  }
}

// qr code modal css end

// streaming modal css start
.streaming-modal {
  .MuiPaper-rounded.MuiDialog-paperWidthSm .MuiButton-containedPrimary,
  .MuiPaper-rounded.MuiDialog-paperWidthSm .MuiButton-outlinedPrimary {
    padding: 0 16px !important;
  }
}

// streaming modal css start

// chat modal css start
.chat-modal {
  .MuiOutlinedInput-root,
  .MuiOutlinedInput-root.Mui-focused {
    border: none !important;
    background-color: #f4f4f4 !important;
  }

  .MuiOutlinedInput-input {
    padding: 17.5px 15px !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }

  .light-blue-bg {
    background-color: #eff8fe;
    padding: 15px;
    border-radius: 10px;
  }

  .MuiSlider-colorPrimary {
    color: $primary;
    padding: 5px 0;
  }

  .MuiPaper-rounded.MuiDialog-paperWidthSm
    .MuiButton-containedPrimary.light-blue-btn,
  .MuiPaper-rounded.MuiDialog-paperWidthSm
    .MuiButton-containedPrimary.light-red-btn {
    background: #dff1ff !important;
    height: 34px !important;
    width: 34px !important;
    padding: 7px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    border-radius: 10px !important;
  }

  .MuiPaper-rounded.MuiDialog-paperWidthSm
    .MuiButton-containedPrimary.light-red-btn {
    background: #f1e6e9 !important;
  }
}

// chat modal css end

.creator-profile-post {
  .social-link-btn {
    font-weight: 500 !important;
    color: $black !important;
  }

  .img-plus-square-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .light-blue-icon-btn.hw-60px {
    border-radius: 16px !important;
  }

  .profile-tabs-creator .MuiTab-textColorPrimary {
    width: 50%;
  }

  .posttabbtn .MuiTab-textColorPrimary {
    width: auto !important;
  }

  .add-labels {
    min-height: 34px !important;
    border: 1px dashed #d9d9d9 !important;
    border-radius: 10px !important;
  }

  &.profile-post .light-blue-icon-btn {
    height: 24px;
    width: 24px;
    min-width: 24px !important;
    min-height: 24px !important;
    border-radius: 5px !important;
  }
}

.checkbox-position {
  position: absolute !important;
  right: 5px;
  top: 5px;
  background: rgba(0, 0, 0, 0.2) !important;
  backdrop-filter: blur(6px);
  border-radius: 10px !important;
  padding: 5px !important;

  .MuiCheckbox-root.MuiCheckbox-colorPrimary {
    padding: 3px;
  }
}

.MuiPopover-root.MuiMenu-root {
  .MuiFormControlLabel-root {
    align-items: center !important;

    .MuiRadio-colorPrimary {
      top: 0px;
    }
  }
}

.timer-menu {
  .MuiMenu-list .MuiMenuItem-root:hover {
    background-color: #fff !important;
    transition: 0.5s;

    p {
      color: $primary !important;
    }
  }
}

// creator vault css start
.creator-vault {
  .fans-profile .post-tab .MuiTab-textColorPrimary {
    min-height: 47px;
    min-width: 100px;
  }

  .profile-post .active .gray-border {
    border: 1px solid $primary !important;
    background-color: #eff8fe !important;
  }
}

// creator vault css end

// poll creator css start
.creator-home-module
  .poll-fans
  .poll-data
  .add-payment-option
  .MuiButton-outlined.add-labels {
  min-height: 40px !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
}

// poll creator css end

// quiz creator css start
.quiz-creator {
  .MuiInputBase-adornedStart {
    height: 66px;
  }

  .MuiFormControlLabel-root .MuiRadio-colorPrimary {
    top: 0;
    padding: 0;
  }

  .MuiOutlinedInput-input {
    padding: 20.5px 15px 20.5px 3px !important;
  }
}

// quiz creator css end

// tag creator css start
.tag-creator {
  .light-blue-bg {
    background-color: #eff8fe;
    border-radius: 16px;
    padding: 20px;

    .blue-border {
      border: 1px solid $primary;
      border-radius: 16px;
      padding: 10px 15px;
    }
  }

  .MuiTabPanel-root {
    padding: 24px 0 15px;
  }
}

// tag creator css end

.file-upload {
  background-color: #fafafa;
  border-radius: 16px;
  border: 2px dashed #d3d3d3;
  padding: 30px;
  text-align: center;

  .MuiButton-textPrimary,
  .MuiButton-textPrimary:hover {
    background: transparent !important;
    padding: 0 !important;
    font-weight: 600;
    text-transform: inherit;
    color: $primary;
  }
}

.schedule-post {
  .MuiPickersDay-root.Mui-selected {
    background-color: $primary !important;
  }

  .MuiDateCalendar-root {
    max-height: 500px !important;
    height: 450px !important;
  }

  .MuiDayCalendar-slideTransition {
    min-height: 350px;
  }

  .MuiYearCalendar-root {
    width: 100%;
    max-height: 385px;

    .MuiPickersYear-yearButton {
      font-size: 1.3rem;
      height: 40px;
      width: 100px;
    }
  }

  .MuiPickersDay-dayWithMargin {
    font-size: 27.75px !important;
    width: 63px !important;
    height: 63px !important;
  }

  .MuiDayCalendar-weekDayLabel {
    font-size: 23.79px !important;
    width: 63px !important;
    height: 63px !important;
  }

  .MuiPickersFadeTransitionGroup-root {
    font-size: 27.75px !important;
    font-weight: 600 !important;
  }

  .MuiPickersArrowSwitcher-button {
    font-size: 2rem !important;
  }

  .MuiPickersCalendarHeader-labelContainer {
    font-size: 27.75px !important;
  }

  .MuiPickersDay-today {
    background-color: $primary !important;
    color: $white !important;
    border-color: $primary !important;
  }

  .MuiListItemButton-root.Mui-selected,
  .MuiListItemButton-root:hover {
    background-color: #f4fafe !important;
    border-radius: 10px;
  }

  @media (max-width: 600px) {
    .MuiDateCalendar-root {
      max-height: 500px !important;
      height: 250px !important;
    }

    .MuiDayCalendar-slideTransition {
      min-height: 190px;
    }

    .MuiYearCalendar-root {
      width: 100%;
      max-height: 385px;

      .MuiPickersYear-yearButton {
        font-size: 18px;
      }
    }

    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      font-size: 16px !important;
      width: 35px !important;
      height: 35px !important;
    }

    .MuiPickersFadeTransitionGroup-root {
      font-size: 14px !important;
    }

    .MuiPickersArrowSwitcher-button {
      font-size: 1.5rem !important;
    }

    .MuiPickersCalendarHeader-root {
      padding-left: 0;
    }

    .MuiPickersCalendarHeader-labelContainer {
      font-size: 16px !important;
    }
  }

  @media (max-width: 400px) {
    .MuiPickersDay-dayWithMargin,
    .MuiDayCalendar-weekDayLabel {
      font-size: 16px !important;
      width: 28px !important;
      height: 28px !important;
    }
  }

  .MuiList-padding {
    max-height: 300px;
    overflow: auto;
    padding-top: 0;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #eee;
  }

  ::-webkit-scrollbar-corner {
    background: #f1f1f1;
  }

  .MuiDateCalendar-root {
    width: 100% !important;
  }
}

// set time modal css start
.set-time-modal {
  .light-blue-btn-icon {
    border-radius: 50px !important;
    min-height: 30px !important;
    min-width: 30px !important;
    width: 32px;
    padding: 7px !important;
  }

  .MuiOutlinedInput-input {
    padding: 12px 15px !important;
    height: 0.9375em;
  }

  .MuiOutlinedInput-root {
    border-radius: 5px !important;
    background: #f4f4f4 !important;
    border: 1.6px solid #f4f4f4 !important;
    width: 130px;
  }
}

// set time modal css end

// vault modal start
.vault-dialog {
  .MuiPaper-rounded.MuiDialog-paperWidthSm {
    max-width: 1342px;
    width: 1342px;
  }

  .MuiPaper-rounded.MuiDialog-paperWidthSm
    .MuiTabs-flexContainer
    .MuiTab-textColorPrimary {
    width: auto;
  }

  .MuiPaper-rounded.MuiDialog-paperWidthSm .MuiTabs-flexContainer {
    border-bottom: none !important;
  }

  .collectionsFans .recent-txtbox .MuiOutlinedInput-input {
    padding: 11.5px 15px !important;
    height: 24px;
  }

  .free-box {
    border: 1px solid #7e7e7e;
    padding: 5px 5px 5px 10px;
    background: rgb(255 255 255 / 28%);
    backdrop-filter: blur(6px);
    position: absolute;
    width: 90%;
    margin: auto;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;

    .tag {
      border-radius: 5px;
      font-size: 14px;
      font-weight: 700;
      padding: 3px 13px;
      color: $white;

      &.purple-tag {
        background: #5a4bad;
      }

      &.red-tag {
        background: #f64027;
      }

      &.white-tag {
        background: #e9e9e9;
        color: $black;
      }
    }
  }
}

//vault modal end

.lockicon-bg {
  background-color: #f2f2f2;
  padding: 3px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;

  .lockicon {
    background-color: $white;
    padding: 4px;
    border-radius: 5px;
  }
}

.text-position {
  height: 32px;
  width: 32px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgb(255 255 255 / 22%);
  backdrop-filter: blur(4px);
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

// full calendar css start
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #f3f3f3;
}

.fc .fc-daygrid-day-number {
  color: $darkblue !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 0;
}

.fc .fc-col-header-cell-cushion {
  color: $darkblue !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.fc .fc-daygrid-day-frame {
  background: #fbfbfb !important;
}

.fc .fc-daygrid-day-top {
  flex-direction: inherit;
  padding: 5px;
}

.fc-scrollgrid-sync-inner {
  padding: 5px;
}

.fc-day-other {
  visibility: hidden;
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc-theme-standard th {
  border: none;
  border-bottom: 1px solid #f3f3f3;
}

.fc-daygrid-day-top {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 10px;
    background: url(./../images/emoji.png) no-repeat;
    height: 8px;
    width: 8px;
  }
}

.fc-today-button.fc-button {
  display: none;
}

.fc-header-toolbar {
  position: relative;
  justify-content: center !important;

  &::after {
    content: "";
    position: absolute;
    background: url(./../images/calendar-header.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 73px;
    display: flex !important;
    justify-content: center !important;
    z-index: 1;
    border-radius: 20px 20px 0 0;
  }

  .fc-toolbar-chunk {
    z-index: 2;
    color: #fff;
  }
}

.fc .fc-button-primary,
.fc .fc-button-primary:hover {
  background-color: rgba(239, 248, 254, 0.31) !important;
  border-color: transparent !important;
  backdrop-filter: blur(6px);
  border-radius: 8px !important;
  height: 32px !important;
  width: 32px !important;
  padding: 5px !important;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc .fc-toolbar-title {
  font-size: 22px !important;
}

.fc .fc-button .fc-icon {
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-button-group {
  position: inherit !important;

  .fc-prev-button {
    position: absolute !important;
    top: 50%;
    left: 30%;
    transform: translate(-30%, -50%);
  }

  .fc-next-button {
    position: absolute !important;
    top: 50%;
    right: 30%;
    transform: translate(-30%, -50%);
  }
}

// full calendar css start

// schedule creator css start
.schedule-creator {
  .MuiTableContainer-root .MuiTable-root {
    border-collapse: separate;
    border-spacing: 0;

    .MuiTableHead-root {
      background: #eff8fe;
    }

    .MuiTableCell-root:first-child {
      border-radius: 0;
    }

    .MuiTableCell-root:last-child {
      border-radius: 0;
    }
  }

  .MuiDataGrid-menuIcon .MuiIconButton-root {
    display: none;
  }

  .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--resizable.MuiDataGrid-columnSeparator--sideRight {
    display: none;
  }

  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus-within {
    outline: none;
  }

  .MuiDataGrid-columnHeader--alignRight,
  .MuiDataGrid-cell--textRight {
    justify-content: flex-start !important;
    display: flex;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
  }
}

// schedule creator css end

// New message creator css start
.new-message-creator {
  .active-messgae {
    border: 1px solid #d4eeff;
    background-color: rgba(39, 153, 246, 0.05) !important;
  }

  .MuiCheckbox-root {
    padding: 0 !important;
  }

  .select-user {
    background-color: #f4fafe;
    padding: 15px 30px;
    font-size: 24px;
    color: $primary;
    border-radius: 10px;
  }

  .message-footer {
    border: 1px solid #e4e4e4;
    border-left: none;

    @media (max-width: 1199px) {
      border-left: 1px solid #e4e4e4;
    }
  }

  .send-box {
    background: #eff8fe;
    border-radius: 10px;
    padding: 10px;

    ul {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      li {
        padding: 6px 15px;
        border-radius: 10px;
        font-size: 16px;
        border: 1px solid #e5e5e5;

        a {
          color: $black !important;
        }
      }
    }
  }
}

// new message creator css end

// filter-subscription css start
.filter-subscription {
  .MuiFormControlLabel-root {
    align-items: center !important;
  }

  .MuiDialog-paperWidthSm .MuiFormControlLabel-root .MuiFormControlLabel-label {
    top: 0;
  }
}

// filter-subscription css end

// subscription modal css start
.send-tips-creator {
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    top: -3px;
  }
}

//subscription modal css end

.release-form {
  .file-upload {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    padding: 10px;
  }
}

// poll modal css start
.poll-modal {
  ul {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    li {
      padding: 6px 15px;
      border-radius: 10px;
      font-size: 16px;
      border: 1px solid #e5e5e5;

      a {
        color: $black !important;
      }

      &.active {
        background: $primary;
        border: 1px solid $primary;

        a {
          color: $white !important;
        }
      }
    }
  }
}

// poll modal css end

// subscription tab css start
.fans-profile .post-tab {
  .subscription-tab {
    .MuiTab-textColorPrimary {
      background: #fff;
      border-radius: 10px;
      min-width: inherit;
      border-bottom: 3px solid transparent;
      color: $black;

      &.Mui-selected {
        background-color: #fff !important;
        color: $primary;
        border-bottom: 3px solid $primary;
        border-radius: 0;
      }
    }

    .MuiTabs-flexContainer {
      gap: 0;
    }

    .MuiTabs-scroller.MuiTabs-fixed {
      border-bottom: 1px solid #f0f0f0;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    margin: 0;
    justify-content: center;
  }

  .MuiAccordionSummary-root {
    min-height: 40px;
  }

  .MuiAccordion-root {
    box-shadow: none !important;
  }
}

// subscription tab css end

.MuiMasonry-root {
  margin: 0;
}

.menu-light-blue-bg {
  background: #eff8fe;
  border-radius: 8px;
  width: 90%;
  margin: auto;

  .MuiMenuItem-root {
    width: 100% !important;
  }
}

.custom-menu-width {
  .MuiPopover-paper {
    width: 270px;
  }

  .MuiMenu-list .MuiMenuItem-root:hover:first-child {
    background-color: transparent !important;
  }
}

.light-gray-tag {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 5px 10px;
}

// Creator bookmark css start
.bookmark-creator .creator-home-module .user-img {
  height: auto;
}

// Creator bookmark css end

// filter subscribe modal css start
.filter-subscribe {
  .MuiBox-root {
    flex-wrap: wrap;
  }

  .MuiFormControlLabel-root {
    align-items: center !important;
    width: 100%;

    .MuiFormControlLabel-label {
      position: relative;
      top: 0;
    }
  }

  .plus-minus-textbox {
    background-color: #f8f8f8;
    border-radius: 10px;
    display: flex;

    .MuiButton-containedPrimary,
    .MuiButton-outlinedPrimary {
      height: 37px;
      min-height: 37px !important;
      padding: 0 10px !important;
      line-height: 9px;
    }

    .MuiOutlinedInput-root {
      border-radius: 0 !important;
      background-color: transparent !important;
      border: none !important;
      width: 65px;
      padding-left: 0;
    }

    .MuiOutlinedInput-input {
      padding: 3.5px 0 !important;
      height: 28px;
      color: $black;
    }

    .light-blue-btn-icon {
      background-color: transparent !important;
      color: $black !important;
    }

    .MuiInputAdornment-positionStart .MuiSvgIcon-root {
      font-size: 1.3rem;
      color: $black;
    }

    .MuiCheckbox-colorPrimary {
      color: #e5e5e5;
    }

    .MuiInputAdornment-positionStart {
      margin-right: 0;
    }

    .MuiFormControlLabel-root {
      color: $black;
    }
  }

  .light-blue-bg-500 {
    .plus-minus-textbox {
      background-color: $primary !important;

      .MuiOutlinedInput-input {
        color: $white;
      }

      .light-blue-btn-icon,
      .MuiSvgIcon-root {
        color: $white !important;
      }
    }
  }
}

// filter subscribe modal css end

// list send modal css start
.list-send-modal {
  .MuiFormControlLabel-root {
    width: auto;
  }

  .light-blue-bg-500 .textprimary {
    color: $primary;
  }
}

// list send modal css end

.MuiDataGrid-root {
  .MuiButton-outlinedPrimary,
  .MuiButton-contained,
  .red-btn {
    min-height: 34px !important;
    height: 34px !important;
    border-radius: 16px !important;
    padding: 0 20px;
    min-width: inherit;
    font-size: 16px !important;
    text-transform: capitalize;
  }

  .MuiDataGrid-cell {
    display: flex;
    align-items: center;
  }

  .MuiDataGrid-row.Mui-selected {
    background-color: $white !important;
  }

  .lightBlueBg {
    background: #eef6fc;
    padding: 5px 15px;
    border-radius: 15px;
  }

  .MuiTypography-root {
    margin-left: 5px;
  }

  .MuiDataGrid-main {
    color: $black;
  }

  .MuiDataGrid-columnHeader {
    color: $darkblue;
    font-size: 17px;
    font-weight: 700;
  }

  .lightgreenBg {
    color: $green;
    background: #eefcf0;
    padding: 5px 15px;
    border-radius: 15px;
    height: 34px;
  }

  .lightgrayBg {
    color: $green;
    background: #f6f6f6;
    padding: 5px 15px;
    border-radius: 15px;
    height: 34px;
  }

  .lightredBg {
    color: $green;
    background: #fff5f5;
    padding: 5px 15px;
    border-radius: 15px;
    height: 34px;
  }

  .lightbluedBg {
    color: $green;
    background: #f1f8fd;
    padding: 5px 15px;
    border-radius: 15px;
    height: 34px;
  }
}

.MuiDataGrid-root {
  border-color: #eeeeee !important;
  border-radius: 16px !important;
}

.MuiDataGrid-row--borderBottom {
  background: $lightbluebg !important;
}

.MuiDataGrid-columnHeader {
  border-bottom: none !important;
}

.MuiDataGrid-cell,
.MuiDataGrid-footerContainer {
  border-top: 1px solid #eeeeee !important;
}

// .MuiDataGrid-footerContainer {
//     display: none !important;
// }

.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
  outline: none;
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
  flex-direction: row !important;
}

.MuiDataGrid-row:hover {
  background-color: rgb(39 153 246 / 5%) !important;
}

.upload-btn {
  border: 2px dashed #d9d9d9;
  border-radius: 10px;
  padding: 3px;
}

// add post modal css start
.add-post-modal {
  .light-gray-btn {
    width: 40px;
    border-radius: 8px;
    padding: 2px 8px !important;
    background-color: #f6f6f6 !important;
    border: 1px solid #f6f6f6 !important;
  }

  .active {
    background-color: $primary;
    border-radius: 10px;

    .text-darkblue {
      color: $white !important;
    }
  }
}

// add post modal css start

.fc .fc-scroller-liquid-absolute {
  overflow: auto !important;
}

// private archive css start
.private-archive {
  .private-archive-bg {
    position: relative;
    border: 2px solid $white;

    &.active.bg-white {
      border: 2px solid $primary;

      .MuiCheckbox-colorPrimary.Mui-checked {
        color: #fff !important;
      }

      .checkbox-bg {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0;
        width: 50px;
        height: 60px;
        border-left: solid 50px transparent;
        border-bottom: solid 50px transparent;
        border-top: solid 50px $primary;
        border-radius: 0px 10px 0px 0px;
      }
    }
  }

  .chkbox-position {
    position: absolute;
    top: -5px;
    right: -7px;
  }

  .selected-data {
    border: 1px solid #bde2ff;
    padding: 7px 15px;
    border-radius: 16px;
    backdrop-filter: blur(6px);
    background: rgb(255 255 255 / 28%);
    position: fixed;
    bottom: 10px;
  }
}

// private archive css start

// post statists css start
.post-statists {
  .light-blue-bg {
    background-color: #f7f8fe;
    padding: 15px;
    border-radius: 16px;

    .border-color {
      border-left: 4px solid #3646f9;
      border-radius: 40px;
      height: 25px;
    }
  }

  .light-orange-bg {
    background-color: #f7f8fe;

    .border-color {
      border-left: 4px solid #d86e70;
    }
  }

  .light-green-bg {
    background-color: #fbf6f8;

    .border-color {
      border-left: 4px solid #1fba95;
    }
  }

  .light-yellow-bg {
    background-color: #fcfcf6;

    .border-color {
      border-left: 4px solid #bab310;
    }
  }

  .light-purple-bg {
    background-color: #faf9fb;

    .border-color {
      border-left: 4px solid #765ea0;
    }
  }

  .MuiCheckbox-colorPrimary {
    padding: 0 !important;
  }

  .statistics-data {
    padding: 2px 20px;
    box-shadow: 0 0 30px rgb(0 0 0 / 8%);
    border-radius: 16px;
  }
}

// post statists css end

.multiline-textarea {
  .MuiInputBase-inputMultiline {
    padding: 0 !important;
  }
}

.loading-user {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.dlt-icon {
  position: absolute;
  top: -8px;
  right: -6px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background: #fff;
  padding: 1px;
}

.creator-message .message-footer .message-width {
  width: calc(100% - 327px);
}

// wishlist page css start
.wishlist-page {
  .fans-profile {
    .post-tab {
      .MuiTab-textColorPrimary.Mui-selected {
        background-color: transparent !important;
        border-bottom: 2px solid $primary;
        border-radius: 0;
        color: $primary;
      }

      .MuiTab-textColorPrimary {
        border-radius: 0;
        color: $black;
        background-color: transparent;
        min-height: 52px;
        padding: 20px 20px;
        border-bottom: 2px solid transparent;
      }
    }
  }
}

// wishlist page css end

// add product css start
.add-product {
  .public-box {
    background-color: #fdfdfd;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    padding: 15px;

    .new-tag {
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: #f64027;
      color: $white;
      border-radius: 10px;
      padding: 7px 12px;
      font-size: 14px;
    }

    .new-tag-primary {
      background-color: $primary;
    }

    .light-blue-btn,
    .light-red-btn {
      min-height: 37px !important;
    }
  }
}

// add product css end

// Promotion Campaign css start
.promotion-campaign {
  .MuiPaper-rounded.MuiDialog-paperWidthSm {
    width: 670px;
  }

  .light-blue-bg {
    .border-b {
      border-bottom: 1px solid #c9e9ff;
    }

    .MuiTabs-flexContainer {
      border-bottom: none;

      .MuiTab-textColorPrimary {
        width: auto;
        font-size: 14px !important;
        font-weight: 600 !important;
        min-height: 37px !important;

        &.Mui-selected {
          color: $white !important;
          font-weight: 600;
          background: $primary;
          border-radius: 10px;
        }
      }
    }

    .MuiTabs-indicator {
      height: 0 !important;
    }
  }

  .MuiOutlinedInput-root,
  .MuiOutlinedInput-root.Mui-focused {
    border: 1.6px solid #d3e1ec !important;
    background-color: transparent !important;
  }
}

// Promotion Campaign css end

// help-page css start
.help-page {
  .help-bg {
    background: url(./../images/help-bg.png) no-repeat;
    background-size: cover;

    .MuiOutlinedInput-root {
      border-radius: 16px !important;
      background: #33a5ff !important;
      border: 1.6px solid #4fc1ff !important;
      color: $white !important;
    }

    .MuiOutlinedInput-input {
      padding: 14.5px 15px !important;
    }
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: $primary !important;
  }

  .MuiTabs-indicator {
    background-color: $primary !important;
  }

  .MuiButtonBase-root.MuiTab-root {
    text-transform: capitalize;
  }

  .MuiTabs-flexContainer {
    border-bottom: 2px solid #f0f0f0;
  }

  .block-country-modal::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: $primary;
    width: 4px;
    border-radius: 40px;
    height: 100%;
  }

  .help-padding {
    padding: 103px 20px;
  }

  .account-info .MuiOutlinedInput-input {
    padding: 0 !important;
    border-radius: 0 !important;
  }
}

// help page css end

// .MuiMasonry-root {
//     // height: auto !important;
// }

// statement page css start
.statement-page {
  .light-blue-btn-icon {
    min-height: 48px !important;
    min-width: 48px !important;
    width: 48px !important;
    height: 48px !important;
    border: none !important;
    border-radius: 100px !important;

    img {
      width: 28px;
      height: 28px;
    }
  }

  .MuiButton-containedPrimary {
    min-height: 40px !important;
  }

  .MuiTab-textColorPrimary {
    min-height: 37px !important;
    padding: 7px 16px !important;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-root {
    min-height: 37px !important;
    .MuiTabs-flexContainer {
      background: $lightbluebg;
      border-radius: 10px;

      .MuiTab-textColorPrimary.Mui-selected {
        background-color: $primary !important;
        color: $white;
        border-radius: 10px;
      }
    }
  }

  .pending-balance .MuiOutlinedInput-input {
    padding: 15.5px 15px !important;
  }

  @media (max-width: 875px) {
    .payment-fans {
      width: 100%;
    }
  }

  .earning-user-data {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .EarningUser {
      height: 93px;
      width: auto;
      margin-bottom: 20px;
    }

    @media (max-width: 1600px) {
      .font-20 {
        font-size: 18px !important;
      }
    }
  }

  .earning-balance-data {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    height: 100%;
    padding: 15px;
  }

  .MuiPaper-root {
    background-color: transparent !important;
    border-radius: 16px;
    .MuiTable-root {
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid #f2f2f2;

      .MuiTableHead-root {
        background: #f1f2fe;

        .MuiTableCell-head {
          border-bottom: none;
          color: $blue;
          font-weight: 600;
        }
      }

      .MuiTableCell-root {
        border: none;
        border-bottom: 1px solid #f2f2f2;
        font-size: 18px;
      }

      .MuiTableFooter-root {
        background: #eff8fe;

        .MuiTableCell-footer {
          color: $primary;
          font-weight: 600;
        }
      }
    }
  }

  .subscription-data-width {
    min-width: 215px;

    .light-blue-bg {
      background: #f1f2fe;
      border-radius: 10px;
      padding: 10px 10px;
      display: flex;
      margin-bottom: 10px;
    }

    .light-pink-bg {
      background: #fdf8f8;

      .pink-left-border {
        border-left: 4px solid #d86e70;
      }
    }

    .light-green-bg {
      background: #effaf7;

      .green-left-border {
        border-left: 4px solid #1fba95;
      }
    }

    .light-yellow-bg {
      background: #fcfbf4;

      .green-left-border {
        border-left: 4px solid #d1cd64;
      }
    }

    .light-purple-bg {
      background: #f5f4f8;

      .purple-left-border {
        border-left: 4px solid #765ea0;
      }
    }

    .light-coffee-bg {
      background: #f7f3ee;

      .coffee-left-border {
        border-left: 4px solid #8b5b0c;
      }
    }
  }
}
// statement page css end

.MuiTooltip-tooltip {
  background-color: $white !important;
  border-radius: 16px !important;
  color: $black !important;
  padding: 15px !important;
  box-shadow: 0 0 30px rgb(0 0 0 / 7%) !important;
}

.red-btn:hover .hover-red-icon {
  filter: hue-rotate(160deg);
}

.transparent-bg {
  background-color: transparent;
}

.bg-primary-10 {
  background-color: rgba($primary, 0.1);
}
