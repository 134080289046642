//
// _gridjs.scss
//

.gridjs-container {
    @apply text-slate-800 ;

}

.gridjs-footer {
    border-left: 1px solid;
    border-right: 1px solid;
    @apply border-slate-200 bg-white shadow-none ;
}

input.gridjs-input {
    @apply border-slate-200 bg-white ;

    &:focus {
        @apply shadow-none border-custom-500;
    }
}

.gridjs-pagination {
    @apply text-custom-600;

    .gridjs-pages {
        button {
            @apply border-slate-200 bg-white ;

            &:focus {
                @apply shadow-md border-r border-slate-200 ;
            }

            &:hover {
                @apply text-slate-600 bg-slate-50 ;
            }

            &:disabled,
            &[disabled],
            &:hover:disabled {
                @apply text-slate-400 bg-white ;
            }

            &.gridjs-spread,
            &.gridjs-currentPage {
                @apply bg-white ;
            }

            &:last-child {
                @apply border-r border-slate-200 ;

            }
        }
    }
}

.gridjs-tbody {
    @apply bg-white ;
}

td {
    &.gridjs-td {
        @apply border-slate-200 bg-transparent ;
    }
}

th {
    &.gridjs-th {
        @apply text-slate-600 bg-slate-50 border-slate-200 ;
    }

    &.gridjs-th-sort {

        &:hover,
        &:focus {
            @apply bg-slate-200 ;
        }
    }

    &.gridjs-th-fixed {
        @apply shadow-md;
    }
}

.gridjs-tr-selected {
    @apply bg-slate-200 ;
}

th.gridjs-th:first-child, td.gridjs-td:first-child{
    border-left: 1px solid;
    @apply border-slate-200 ;
}

th.gridjs-th:last-child, td.gridjs-td:last-child {
    border-right: 1px solid;
    @apply border-slate-200 ;
}

.gridjs-wrapper {
    @apply border-slate-200 shadow-none ;
}