@import "./variable.scss";

body.dark,
.dark .MuiButton-outlinedPrimary,
.dark .MuiPickersArrowSwitcher-button,
.dark .MuiPickersCalendarHeader-switchViewButton,
.dark .MuiDayCalendar-weekDayLabel,
.dark .MuiPickersDay-dayWithMargin {
  color: $white !important;
}

.dark .ripLine {
  border-top: 3px dashed $darkbg !important;
}

.dark .MuiDrawer-paper,
.dark .sidebar-user-box h3,
.dark .text-darkblue,
.dark .MuiInputBase-input,
.dark .MuiPaper-rounded.MuiDialog-paperWidthSm .MuiDialogTitle-root,
.dark .MuiFormControl-root .MuiTypography-root,
.dark .MuiPaper-rounded.MuiDialog-paperWidthSm,
.dark .text-darkblue,
.dark .subscription-plan .expire-plan .bg-primary,
.dark .subscription-plan .expire-plan .Mui-disabled,
.dark .MuiSelect-iconOutlined,
.dark .cart-fans .plus-minus-textbox .MuiButton-root,
.dark
  .creator-home-module
  .thumbnail-text
  .btn-white.MuiButton-containedPrimary,
.dark .creator-home-module .varified h3,
.dark .product-detail .plus-minus-txtbox .MuiIconButton-sizeMedium,
.dark .payment-fans .credit-card-radio .MuiFormControlLabel-label,
.dark
  .friend-box.active
  .MuiCheckbox-colorPrimary.Mui-checked
  .MuiSvgIcon-fontSizeMedium,
.dark .friend-box.active .chkbox-position,
.dark .add-queue-module .MuiTab-textColorPrimary.Mui-selected,
.dark .setting-drawer .MuiListItemText-primary,
.dark .fc .fc-daygrid-day-number,
.dark .fc .fc-col-header-cell-cushion,
.dark .MuiIconButton-edgeEnd .MuiSvgIcon-root,
.dark
  .private-archive-bg
  .MuiCheckbox-colorPrimary.Mui-checked
  .MuiSvgIcon-fontSizeMedium {
  color: $white !important;
}

.dark
  .MuiPaper-rounded.MuiDialog-paperWidthSm
  .MuiTabs-flexContainer
  .MuiTab-textColorPrimary {
  color: $white !important;
}

body.dark,
.dark .heading .post-tab .MuiTabs-flexContainer,
.dark .heading .post-tab .MuiTabs-flexContainer .MuiTab-textColorPrimary,
.dark .collectionsFans .recent-txtbox .MuiOutlinedInput-root,
.dark .fans-profile .MuiTab-labelIcon,
.dark .light-gray-bg,
.dark .sidebar .MuiListItemButton-root.MuiButtonBase-root:hover,
.dark .MuiAppBar-colorPrimary .css-96uuyl,
.dark .MuiIconButton-sizeLarge,
.dark .light-blue-bg,
.dark .MuiOutlinedInput-root,
.dark .available-tag,
.dark .subscription-plan .expire-plan .bg-primary,
.dark .subscription-plan .expire-plan .Mui-disabled,
.dark .payment-fans .payment-icons,
.dark .MuiPopover-paper.MuiMenu-paper .MuiMenuItem-root.Mui-selected,
.dark .plus-minus-textbox .MuiButton-root,
.dark .plus-minus-textbox .MuiInputBase-root .MuiInputBase-input,
.dark .order-detail .MuiTimelineItem-positionRight .MuiTimelineConnector-root,
.dark .fans-notification .light-gray-box,
.dark .subscription .ppp-img-bg,
.dark .fans-message .left-side .css-96uuyl,
// .dark .fans-message .left-side .message-list,
.dark .fans-message .message-body .sender-msg,
.dark .fans-message .message-body .reply-msg,
.dark .fans-message .message-footer,
.dark .lightgray-400,
.dark .light-blue-bg-500,
.dark .creator-home-module .thumbnail-text .btn-white.MuiButton-containedPrimary,
.dark .creator-home-module .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-input,
.dark .creator-home-module .MuiFormControl-root.MuiTextField-root,
.dark .subscription  .post-tab .MuiTabs-flexContainer,
.dark .light-red-bg,
.dark .activenow p,
.dark .light-gray-btn,
.dark .streams .lighrgraybg,
.dark .product-detail .plus-minus-txtbox .MuiIconButton-sizeMedium,
.dark .product-detail .plus-minus-txtbox .MuiOutlinedInput-input,
.dark .streams .card-hover-icon .MuiButton-outlinedPrimary,
.dark .disabled, .disabled:hover,
.dark .whitebtn, .whitebtn:hover,
.dark .search-profile .css-96uuyl,
.dark .account-verified,
.dark .MuiTabs-flexContainer .MuiTab-textColorPrimary,
.dark .select-user-msg,
.dark .light-blue-bg-700,
.dark .search-profile .lightgray-btn,
.dark .separator .line,
.dark .MuiButton-containedPrimary.Mui-disabled,
.dark .MuiMenu-list .MuiMenuItem-root:hover,
.dark .MuiMenu-list .varified .verified-bg,
.dark .MuiPopover-paper.MuiMenu-paper,
.dark .css-fj1yqy-MuiPaper-root-MuiPopover-paper-MuiMenu-paper::before,
.dark .profile-post .posttabbtn .MuiTab-labelIcon,
.dark .fans-profile .post-tab .MuiTab-textColorPrimary,
.dark .creator-home-module .post-tab .MuiTabs-flexContainer,
.dark .creator-home-module .post-tab .MuiTab-textColorPrimary,
.dark .add-queue-module .MuiTab-textColorPrimary,
.dark .subscription .post-tab .MuiTab-textColorPrimary,
.dark .add-queue-module .MuiTabs-flexContainer .MuiTab-textColorPrimary,
.dark .setting-drawer .MuiListItemButton-gutters,
.dark .light-gray700-bg,
.dark .file-upload, .dark .light-blue-btn-icon,
.dark .schedule-post .MuiListItemButton-root.Mui-selected, 
.dark .creator-vault .profile-post .active .gray-border,
.dark .schedule-post .MuiListItemButton-root:hover,
.dark .separator p,
.dark .lockicon-bg,
.dark .fc .fc-daygrid-day-frame,
.dark .new-message-creator .select-user,
.dark .send-box,
.dark .new-message-creator .active-messgae,
.dark .drag-content,
.dark .light-gray-tag, 
.dark .filter-subscribe .plus-minus-textbox,
.dark .fans-profile .post-tab .MuiTab-textColorPrimary,
.dark .MuiDataGrid-row--borderBottom,
.dark .dlt-icon,
.dark .add-product .public-box,
.dark .MuiDataGrid-root .MuiDataGrid-row.Mui-selected,
.dark .promotion-campaign .MuiTabs-flexContainer .MuiTab-textColorPrimary,
// .dark .statement-page .MuiTabs-root .MuiTabs-flexContainer,
.dark .statement-page .MuiTabs-flexContainer .MuiTab-textColorPrimary,
.dark .statement-page .MuiTabs-root .MuiTabs-flexContainer,
.dark .statement-page .MuiPaper-root .MuiTable-root .MuiTableHead-root,
.dark .statement-page .MuiPaper-root .MuiTable-root .MuiTableFooter-root,
.dark .MuiDrawer-paperAnchorLeft .MuiListItemButton-root.MuiButtonBase-root.active {
  background-color: $darkSecondary !important;
}

.dark .statement-page .MuiTabs-flexContainer .MuiTab-textColorPrimary {
  background-color: transparent !important;
}

.dark .MuiAppBar-colorPrimary, 
.dark .bg-white,
.dark .circleLeft,
.dark .circleRight,
.dark .MuiButton-outlinedPrimary,
.dark .MuiPaper-rounded.MuiDialog-paperWidthSm,
.dark .MuiCard-root, 
.dark .MuiAccordion-root, 
.dark .subscription-plan .sub-plan-bg,
.dark .MuiDrawer-paper,
// .dark .MuiPopover-paper.MuiMenu-paper,
.dark .MuiPaper-root,
.dark .fans-message .left-side .message-list,
// .dark .fans-message .message-footer .MuiOutlinedInput-root,
.dark .fans-message .message-body,
.dark .auth-page .MuiInputBase-formControl,
.dark .auth-page .MuiButton-containedPrimary.Mui-disabled,
.dark .bg-white.profile-tab,
.dark .MuiTabs-flexContainer .MuiTab-textColorPrimary,
.dark .MuiMenu-list .MuiMenuItem-root:hover,
.dark .h-queue .post-tab .MuiTabs-flexContainer,
.dark .creator-home-module .discount-bg,
.dark .lockicon,
.dark .lightgaybg-100,
.dark .wishlist-page .MuiTabs-flexContainer .MuiTab-textColorPrimary,
// .dark .wishlist-page .MuiTab-textColorPrimary.Mui-selected,
// .dark .fans-profile .post-tab .MuiTab-textColorPrimary,
.dark .promotion-campaign .MuiTabs-flexContainer .MuiTab-textColorPrimary.Mui-selected,
.dark .fans-profile .post-tab .subscription-tab .MuiTab-textColorPrimary.Mui-selected {
  background-color: $darkbg !important;
}

.dark .heading .post-tab .MuiTab-textColorPrimary.Mui-selected,
.dark .fans-profile .post-tab .MuiTab-textColorPrimary.Mui-selected,
.dark .subscription .post-tab .MuiTab-textColorPrimary.Mui-selected,
.dark .creator-home-module .post-tab .MuiTab-textColorPrimary.Mui-selected,
.dark .add-queue-module .MuiTab-textColorPrimary.Mui-selected,
.dark .filter-subscribe .light-blue-bg-500 .plus-minus-textbox,
.dark
  .filter-subscribe
  .light-blue-bg-500
  .plus-minus-textbox
  .light-blue-btn-icon,
.dark
  .filter-subscribe
  .light-blue-bg-500
  .plus-minus-textbox
  .MuiOutlinedInput-root,
.dark
  .filter-subscribe
  .light-blue-bg-500
  .plus-minus-textbox
  .MuiOutlinedInput-input {
  background-color: #2799f6 !important;
}

.dark
  .filter-subscribe
  .light-blue-bg-500
  .plus-minus-textbox
  .MuiInputBase-formControl {
  border: 1px solid $primary !important;
}

.dark .add-post-modal .active .light-gray-btn {
  background-color: $white !important;
  border: 1px solid $white !important;

  .img-dark-filter {
    filter: inherit !important;
  }
}

.dark .thankyou-modal .cart-fans .border-b-dashed {
  border-bottom: 2px dashed #e2e2e2 !important;
}

.dark .MuiButton-containedPrimary:hover,
.dark .fans-profile .account-info .MuiOutlinedInput-root,
.dark .fans-profile .camera-btn {
  background: $darkSecondary !important;
}

.dark .post-statists .statistics-data {
  box-shadow: 0 0 30px rgb(239 239 239 / 8%);
}

.dark .subscription-plan .expire-plan .Mui-disabled,
.dark .payment-fans .payment-icons,
.dark .plus-minus-textbox .MuiInputBase-root,
.dark
  .order-detail
  .order-detail-tbl
  .MuiTableContainer-root
  .MuiTableCell-root,
.dark
  .creator-home-module
  .thumbnail-text
  .btn-white.MuiButton-containedPrimary,
.dark .light-gray-btn,
.dark .disabled,
.disabled:hover,
.dark .whitebtn,
.whitebtn:hover,
.dark .search-profile .lightgray-btn,
.dark .MuiButton-containedPrimary.Mui-disabled,
.dark .MuiButton-outlinedPrimary,
.dark .gray-border-box,
.dark .add-product .public-box {
  border: 1px solid $darkSecondary !important;
}

.dark .collectionsFans .customer-box,
.dark .profile-post .profile-media .gray-border,
.dark .gray-border,
.dark .send-tips .radio-btn-border,
.dark .MuiTableCell-root,
.dark .public-page .public-box,
.dark .border,
.dark .fc-theme-standard td,
.dark .fc-theme-standard th,
.dark .message-footer,
.dark .new-message-creator .send-box ul li,
.dark .new-message-creator .active-messgae,
.dark .dlt-icon {
  border: 1px solid $darkborder !important;
}

.dark
  .wishlist-page
  .fans-profile
  .post-tab
  .MuiTab-textColorPrimary.Mui-selected {
  background-color: transparent !important;
}

.dark .MuiDataGrid-root {
  border-color: $darkborder !important;
}

.dark .message-footer {
  border-left: none !important;
  @media (max-width: 1199px) {
    border-left: 1px solid $darkborder !important;
  }
}

.dark .send-tips .add-payment-option .MuiButton-outlined,
.dark .poll-fans .poll-data .add-payment-option .MuiButton-outlined,
.dark .file-upload {
  border: 2px dashed $darkborder !important;
}

.dark .border-t,
.dark .MuiDataGrid-cell,
.dark .MuiDataGrid-footerContainer {
  border-top: 1px solid $darkborder !important;
}

.dark .cart-fans .border-b-dashed {
  border-bottom: 1px dashed $darkborder !important;
}

.dark .MuiOutlinedInput-root {
  border: 1.6px solid $darkborder !important;
}

.dark .border-b,
.dark .borderBottom,
.dark .product-detail .MuiAccordion-rounded.MuiAccordion-gutters,
.dark .css-1gsv261,
.dark .border-bottom-gray,
.dark
  .fans-profile
  .post-tab
  .subscription-tab
  .MuiTabs-scroller.MuiTabs-fixed {
  border-bottom: 1px solid $darkborder !important;
}

.dark .MuiPaper-rounded.MuiDialog-paperWidthSm .MuiTabs-flexContainer,
.dark .help-page .MuiTabs-flexContainer {
  border-bottom: 1px solid $darkborder;
}

.dark .promotion-campaign .MuiTabs-flexContainer {
  border-bottom: none !important;
}

.dark .border-r,
.dark .message-body {
  border-color: $darkborder !important;
}

.dark .poll-fans .MuiDivider-root {
  border-color: $darkSecondary !important;
}

.dark .light-blue-icon-btn,
.dark .white-btn {
  border: 1px solid $darkSecondary !important;
  background-color: $darkSecondary !important;
}

.dark .MuiRadio-colorPrimary,
.dark .creator-home-module .varified p,
.dark .text-black,
.dark
  .product-detail
  .MuiAccordion-rounded.MuiAccordion-gutters
  .MuiAccordionSummary-content,
.dark .MuiAccordionSummary-expandIconWrapper,
.dark .MuiPaper-elevation,
.dark .profile-post .profile-tabs .MuiTab-textColorPrimary,
.dark
  .noarticle
  .MuiAccordion-rounded
  .MuiAccordionSummary-root
  .MuiAccordionSummary-content,
.dark .auth-page h1,
.dark .MuiFormControlLabel-root,
.dark .profile-gallery-btn span,
.dark .MuiDataGrid-root,
.dark .MuiDataGrid-main,
.dark .MuiDataGrid-columnHeader,
.dark .MuiTablePagination-toolbar,
.dark .MuiTablePagination-actions .MuiButtonBase-root,
.dark .MuiDataGrid-iconButtonContainer .MuiIconButton-root,
.dark .help-page .MuiTab-textColorPrimary,
.dark .statement-page .MuiTabs-flexContainer .MuiTab-textColorPrimary,
.dark .MuiTable-root .MuiTableCell-root {
  color: rgb(255 255 255 / 72%) !important;
}

.dark .order-detail .MuiTimelineItem-positionRight .MuiTimelineDot-filled {
  border-color: $darkSecondary;
  background-color: $darkbg;
}

.dark .order-detail .MuiTimelineItem-positionRight .MuiTimelineDot-filled.blue {
  border-color: $primary;
  background-color: $darkbg;
}

.dark
  .order-detail-tbl
  .MuiTableContainer-root
  .MuiTableCell-root:nth-child(2) {
  border-left: none !important;
  border-right: none !important;
}

.dark .order-detail-tbl .MuiTableContainer-root .MuiTableCell-root:first-child {
  border-right: none !important;
}

.dark .order-detail-tbl .MuiTableContainer-root .MuiTableCell-root:last-child {
  border-left: none !important;
}

.dark .MuiDivider-root {
  border-color: $darkSecondary !important;
}

.dark .heading img,
.dark .heading .no-filter-img {
  filter: inherit !important;
}

.dark .plus-minus-txtbox img,
.dark .img-dark-filter,
.dark .sidebar .MuiList-padding img,
.dark .css-96uuyl img,
.dark .heading img,
.dark .message-footer .footer-icons img,
.dark .-rotate-90.transform,
.dark .MuiTab-textColorPrimary.Mui-selected img,
.dark .MuiListItemIcon-root img,
.dark .MuiDialogTitle-root .MuiIconButton-sizeSmall img {
  filter: brightness(0) invert(1) !important;
}

.dark .sidebar .MuiListItemButton-root.MuiButtonBase-root:hover {
  background-color: #313642 !important;
}

.dark .fans-message .message-body .sender-msg::before {
  border-color: transparent transparent $darkSecondary transparent;
}

.dark .fans-message .message-body .reply-msg::before {
  border-color: transparent transparent $darkSecondary transparent;
}

.dark .MuiFormControlLabel-root .MuiSwitch-track {
  border: 1.5px solid #383d42;
}

.dark .MuiDivider-root,
.dark .request-photo-modal .plus-minus-textbox {
  border-color: $darkbg !important;
}

.dark .MuiFormControlLabel-root .Mui-checked + .MuiSwitch-track {
  border: 1.5px solid $primary;
}

.dark .MuiFormControlLabel-root .MuiSwitch-track,
.dark .MuiFormControlLabel-root .Mui-checked + .MuiSwitch-track {
  background-color: #6c6c6c !important;
}

.dark .MuiCheckbox-colorPrimary .MuiSvgIcon-fontSizeMedium {
  color: #6c6c6c;
}

.dark .MuiCheckbox-colorPrimary.Mui-checked .MuiSvgIcon-fontSizeMedium,
.dark .profile-post .profile-tabs .MuiTab-textColorPrimary.Mui-selected,
.dark .help-page .MuiTab-textColorPrimary.Mui-selected {
  color: $primary !important;
}

.dark .poll-fans .poll-data,
.dark .shadowsm {
  box-shadow: 0 0 10px #323232;
}

.dark .profile-bg-white {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgb(7 14 26 / 74%);
    z-index: 1;
  }
}

.dark .poll-fans .poll-data .remove-outline-circle {
  background: transparent;
}

.dark .help-page .help-bg .MuiOutlinedInput-root {
  background: #33a5ff !important;
  border: 1.6px solid #4fc1ff !important;
  color: #fff !important;
}

@media (max-width: 767px) {
  .dark .sidebar {
    background-color: $darkbg !important;
  }
}

.MuiTypography-root {
  color: "red";
}

.dark .MuiTimePicker-root .MuiInputBase-input,
.dark .MuiTimePicker-root .MuiInputLabel-root,
.dark .MuiClock-pmButton,
.dark .MuiClock-amButton,
.dark .MuiClockNumber-root {
  color: $white !important;
}

.dark .MuiTimePicker-root {
  .MuiOutlinedInput-root {
    color: $white !important;

    .MuiOutlinedInput-input {
      color: $white !important;
    }
  }

  .MuiIconButton-root {
    color: $white !important;
  }
}
