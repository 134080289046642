@media (min-width: 1700px) {
    .container {
        max-width: 1640px;
    }
}

@media (min-width: 1200px) {
    .xl\:mr-6 {
        margin-right: 1.5rem !important;
    }

    .xl\:mr-0 {
        margin-right: 0 !important;
    }

    .xl\:mr-4 {
        margin-right: 1rem !important;
    }
}

@media (min-width: 768px) {
    .md\:pr-3 {
        padding-right: .75rem !important;
    }
}

@media (min-width: 640px) {
    .sm\:pr-2 {
        padding-right: .5rem !important;
    }

    .sm\:pl-2 {
        padding-left: .5rem !important; 
    }
}

@media (max-width: 1699px) {
    .container {
        max-width: calc(100% - 40px);
        padding:  0 20px;
    }

    .h-215px {
        height: 175px;
    }

    .message-list {
        .font-20 {
            font-size: 16px !important;
        }
    }
}

@media (max-width: 1500px) {
    .XXl-flex-wrap {
        flex-wrap: wrap;
    }

    
    .fc-button-group .fc-prev-button {
        left: 25%;
        transform: translate(-25%, -50%);
    }

    .fc-button-group .fc-next-button {
        right: 25%;
        transform: translate(-25%, -50%);
    }
}

@media (max-width: 1300px) {
    .flex-md-wrap {
        flex-wrap: wrap;
    }

    .creator-home-module .profile-images .user-img, .creator-home-module .profile-images .blue-bg, .creator-home-module .profile-images .light-blue-bg  {
        min-width: 70px;
        height: 90px;
        width: 70px;
    }

    .creator-home-module .profile-images .user-img img:first-child {
        min-width: 70px;
        height: 85px;
        width: 70px;
    }

    .h-165px {
        height: 110px;
    }

    .h-215px {
        height: 125px;
    }

    .fans-message .message-footer .message-width {
        width: 100%;
    }

    .fc-button-group .fc-prev-button {
        left: 25%;
        transform: translate(-25%, -50%);
    }

    .fc-button-group .fc-next-button {
        right: 25%;
        transform: translate(-25%, -50%);
    }
}

@media (max-width: 1200px) {
    .add-queue-module .h-queue {
        height: 100%;
    }

    .subscription {
        margin-top: 15px !important;
    }

    .h-400px, .h-165px, .h-235px, .h-560px {
        height: auto;
    }

    .hw-90px {
        min-height: 173px;
    }

    .h-215px {
        height: 185px;
    }

    .profile-post-btns {
        flex-wrap: wrap;
    }

    .fans-notification .h-calc-200,
    .fans-message .h-calc-200,
    .message-body.h-calc-200 {
        height: auto;
        min-height: inherit;
    }

    .fans-message .border-r {
        border-right-width: 0;
    }

    .fans-profile .MuiMasonry-root {
        margin: 0;
        height: inherit;
    }
}

@media (max-width: 991px) {
    .flex-sm-wrap {
        flex-wrap: wrap;
    }

    .profile-post {
        .profile-lock-icon {
            width: 300px;
        }
    }
    
    .h-calc-200 {
        height: auto;
        min-height: inherit;
    }

    .fc-button-group .fc-prev-button {
        left: 15%;
        transform: translate(-15%, -50%);
    }

    .fc-button-group .fc-next-button {
        right: 15%;
        transform: translate(-15%, -50%);
    }

    .fans-message .select-user-msg {
        font-size: 20px;
        padding: 10px 20px;
    }

    .fans-notification .light-gray-box {
        flex-direction: column;

        .gap-6 {
            gap: 0.5rem 1.5rem;
        }

        .date-position {
            position: absolute;
            right: 20px;
            width: 75px;
            text-align: right;
            min-width: 75px;
        }
    }

    .h-195px {
        height: auto;
    }

    .h-215px {
        height: 125px;
    }

    .h-275px {
        height: auto;
    }

    .hw-90px {
        min-height: 100px;
    }

    .help-page .help-padding {
        padding: 0px 20px 50px;
    }
}

@media (max-width: 899px) {
    .fans-message .left-side .post-tab .h-530px {
        min-height: 150px;
        height: auto;
    }

    .chat-gallery .MuiGrid-grid-md-12 {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .h-calc-200 {
        min-height: auto;
    }

    // .fans-message .message-body, .fans-message .message-footer {
    //     margin-left: -12px;
    // }

    .fans-profile .profile-user {
        top: -34px;
        left: 35px;
        height: 90px;
        width: 90px;
    }

    .w-400px {
        width: 300px;
    }

    .fc-button-group .fc-prev-button {
        left: 10%;
        transform: translate(-10%, -50%);
    }

    .fc-button-group .fc-next-button {
        right: 10%;
        transform: translate(-10%, -50%);
    }
}

@media (max-width: 767px) {
  .css-1cx452u, .css-1o9iyla, .MuiDrawer-paper {
      width: 100% !important;
  }

  .w-675px {
    max-width: 100%;
  }

  .user-name {
    white-space: nowrap;
    width: calc(100vw - 72px) !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .other-user-cover-img {
    height: 90px !important;
  }

  .other-user-profile-img {
    width: 64px !important;
    max-width: 64px !important;
    height: 64px !important;
    top: 10px !important;
    left: 0px !important;
  }

  .profile-container {
    gap: 10px !important;
  }

  // .explorerfans-page .MuiMasonry-root .masonary-box {
  //   // width: calc(49% - 10px) !important;
  // }

    .MuiPaper-rounded.MuiDialog-paperWidthSm .MuiButton-containedPrimary, .MuiPaper-rounded.MuiDialog-paperWidthSm .MuiButton-outlinedPrimary {
        padding: 0 20px !important;
    }

    .css-1ioxarv {
        width: 100% !important;
    } 

    .MuiPaper-rounded.MuiDialog-paperWidthSm .MuiDialogTitle-root h3 {
        font-size: 17px !important;
        padding: 0 !important;
    }

    .new-message-creator .select-user {
        font-size: 18px;
    }

    .collectionsFans .light-gray-bg {
        padding: 8px;
    }

    .font-25, .font-22, .font-24, .font-26, .font-28, .font-38, .font-40, .font-45 {
        font-size: 18px !important;
    } 

    .font-50 {
        font-size: 25px !important;
        line-height: 30px;
    }

    .h-455px {
        height: 390px;
    }
    
    .hw-105px {
        height: 80px;
    }

    .h-215px {
        height: 175px;
    }

    .wd-24rem {
        width: auto !important;
    }

    .font-20 {
        font-size: 16px !important;
    }

    .MuiList-root {
        max-width: 100% !important;
    }

    // .creator-home-module {
    //     margin-top: 15px !important;
    // }

    .creator-home-module .thumbnail-text {
        width: 100%;
    }

    .font-18 {
        font-size: 16px;
    }
    
    .hw-90px {
        min-height: 205px;
    }

    .sidebar .sidebar-sticky {
        width: 60px;
    }

    .sidebar {
        background: #fff !important;
    }

    main {
        margin-top: 10px;
    }

    .sidebar .MuiListItemButton-root.MuiButtonBase-root .MuiListItemIcon-root {
        justify-content: center;
    }

    .sidebar-user-box img {
        height: 40px;
        width: 40px;
    }

    .sidebar .MuiButton-containedPrimary {
        min-width: 40px;
        padding: 6px 5px;
        border-radius: 50% !important;
        min-height: 40px !important;
        height: 40px;
        width: 40px;
        margin: auto;
        display: block;
    }
    
    .sidebar .MuiListItemButton-root {
        padding-left: 6px;
        padding-right: 6px;
    }

    .sidebar .hidden {
        display: none !important;
    }

    .sidebar .sidebar-sticky .MuiListItemButton-root.MuiButtonBase-root {
        margin-right: 0;
        justify-content: center;
    }
    
    .flex-xs-nowrap {
        flex-wrap: wrap;
    }

    .profile-post .profile-user {
        top: 10px;
    }

    .profile-post {
        .available-tag {
            right: 5px;
        }

        .profile-post-tag {
            left: 8px;
        }
    }

    .container {
        max-width: 100%;
    }

    .font-28 {
        font-size: 24px !important;
    }

    .font-26 {
        font-size: 22px !important;
    }
}

@media (max-width: 500px) {
    .gray-border .light-blue-icon-btn {
        min-height: 40px !important;
    }

    .flex-xxs-wrap {
        flex-wrap: wrap;
    }

}

@media (max-width: 450px) {
    .w-400px {
        width: 100%;
    }  

    .h-455px {
        height: 300px;
    }

    .hw-90px {
        min-height: 100px;
    }

   .profile-media .MuiGrid-item {
        max-width: 100% !important;
        flex-basis: 100% !important;
    }

    .h-215px {
        height: auto;
    }

    .hw-105px {
        height: 55px;
    }

    .cart-fans table {
        min-width: 450px;
    }

    .fc-button-group .fc-prev-button {
        left: 5%;
        transform: translate(-5%, -50%);
    }

    .fc-button-group .fc-next-button {
        right: 5%;
        transform: translate(-5%, -50%);
    }
}

// @media (max-width: 320px) {
//   .explorerfans-page .MuiMasonry-root .masonary-box {
//     // width: calc(99% - 10px) !important;
//   }
// }

