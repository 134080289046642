$primary: #2799F6;
$darkblue: #1E405C;
$secondary: #969AB8;
$blue: #0062FF;
$black: #65717B;
$lightbluebg: #EFF8FE;
$white: #fff;
$bordercolor: #D3E1EC;
$gray500: #969AB8;
$darkblack: #171725;
$red: #F64027;
$lightgraybg: #F9F9F9;
$lightgray400: #F4F4F4;
$green: #3CA70A;
$lightcoffee: #D67720;
$black200: #292D32;
$lightgray300: #CACACA;
$lightgay700: #F7F7F7;

$darkbg: #0f172a;
$darkSecondary: #1e293b;
$darkborder: #374252;