/* @layer tailwind-base, antd; */

@layer tailwind-base;

@layer tailwind-base {
  @tailwind base;
}


@tailwind components;
@tailwind utilities;
